import React from "react";
import "./AdInactive.css";
import { useNavigate } from "react-router";
import Heder from "../Component/Heder";

const AdInactive = ({setting}) => {
  const navigate = useNavigate();

  const handleMyAd = () => {
    navigate("/my_online_ads");
  };

  return (
    <>
    <Heder name="ad inactive" setting={setting}/>
      <div className="inactive_div">
        <div className="container">
          <div className="box_inactive_main_div">
            <div className="box_inactive">
              <h1>This ad is no longer active</h1>
              <p>
                This ad seems to be inactive at the moment. Please use the
                search above to find a list of active ads on our website.
              </p>
              <p>
                If you own this ad and want to make it active again, please{" "}
                <span className="access_ad" onClick={handleMyAd}>
                  access your ads.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default AdInactive;