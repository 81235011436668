import React, { useEffect, useRef, useState } from "react";
import "./Register.css";
import { useNavigate } from "react-router";
import { FaHeart, FaRegEye, FaRegEyeSlash, FaUserPlus } from "react-icons/fa";
import axios from "axios";
import { DealerApi } from "./Api";
import { Toast } from "react-bootstrap";
import { IoCreate } from "react-icons/io5";
import ReCAPTCHA from "react-google-recaptcha";
import Heder from "./Heder";

const Register = ({setting}) => {

  const [showPassword, setShowPassword] = useState(false);
  const initialValues = {
    user_type: "",
    company_name: "",
    contact_name: "",
    email: "",
    mobile: "",
    address_one: "",
    county_id: null,
    town_id: null,
    username: "",
    password: "",
    newsletter: "no",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [counties, setCounties] = useState([]);
  const [towns, setTowns] = useState([]);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordDescription, setPasswordDescription] = useState("");
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const navigate = useNavigate();
  const pwdStrengthWrapRef = useRef(null);

  const handleTerms = () => {
    navigate("/terms_and_conditions");
  };

  useEffect(() => {
    const fetchCounties = async () => {
      try {
        const response = await axios.get(`${DealerApi}/county`);
        setCounties(response.data.body.result);
      } catch (error) {}
    };

    fetchCounties();
  }, []);

  const fetchTowns = async (countyId) => {
    try {
      const response = await axios.get(`${DealerApi}/town/${countyId}`);
      setTowns(response.data.body.result);
    } catch (error) {}
  };

  const fieldTypes = {
    county_id: "number",
    town_id: "number",
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const dataType = fieldTypes[name];
    let updatedValue = value;

    if (type === "checkbox") {
      updatedValue = checked ? "yes" : "no";
    } else if (type === "radio") {
      updatedValue = value;
    } else if (dataType === "number") {
      updatedValue = Number(value);
    }
    setFormValues({ ...formValues, [name]: updatedValue });

    // if (name == "recaptcha") {
    //   setRecaptchaValue(e.target.checked);
    //   setError(null);
    // }

    if (name == "password") {
      handlePasswordStrength(value);
    }

    if (value == "business") {
      setModalVisible(true);
      document.body.classList.add("modal-open");
    }

    if (name == "county_id") {
      fetchTowns(value);
    }
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handlePasswordStrength = (password) => {
    let score = 0;
    let message = "Password not entered";

    if (password.length > 0) {
      if (password.length >= 6) {
        score++;
      }

      if (/\d/.test(password)) {
        score++;
      }

      if (/[A-Z]/.test(password) && /[a-z]/.test(password)) {
        score++;
      }

      if (/[!@#$%^&*?_~()-]/.test(password)) {
        score++;
      }

      const descriptions = ["Too short", "Weak", "Good", "Strong", "Best"];
      message = descriptions[score];
    }

    setPasswordStrength(score);
    setPasswordDescription(message);
  };

  const validateForm = () => {
    let errors = {};
    // const regexPattern =
    //   /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{4,32}$/;
    // const regexNumber = /^[0-9]{1,20}$/;
    // const regexNumber = /^[\+\-()0-9]{1,20}/;
    const regexNumber = /^[\+\-()0-9\s]{1,20}$/;

    if (!formValues.user_type) {
      errors.user_type = "User type is required";
    }

    if (!formValues.contact_name) {
      errors.contact_name = "Full Name is required";
    }

    if (!formValues.email) {
      errors.email = "Email is required";
    }

    if (!formValues.mobile) {
      errors.mobile = "Primary Telephone is required";
    } else if (!regexNumber.test(formValues.mobile)) {
      errors.mobile = "Primary Telephone should have maximum length 20";
    }

    if (!formValues.address_one) {
      errors.address_one = "Address is required";
    }

    if (!formValues.county_id) {
      errors.county_id = "Please Select County";
    }

    if (!formValues.town_id) {
      errors.town_id = "Please Select Town";
    }

    if (!formValues.username) {
      errors.username = "Username is required";
    }

    if (!formValues.password) {
      errors.password = "Password is required";
    } else if (passwordStrength < 3) {
      setPasswordFocused(true); // Show password tips if password is weak
    }
    // else if (!regexPattern.test(formValues.password)) {
    //   errors.password = "Password must be at least 6 characters, include at least one number, one lowercase and one uppercase letter, and one special character.";
    // }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      setError("Please complete the ReCAPTCHA verification.");
      return;
    }

    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      axios
        .post(`${DealerApi}/auth/register`, formValues, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setFormValues(initialValues);
          if (res.status === 200) {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            setSuccess(res.data.body.message);
            setError(null);
            setTimeout(() => {
              navigate("/verify_account");
              setSuccess(null);
            }, 5000);
          }
        })
        .catch((error) => {
          setSuccess(null);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setError(error.response.data.body.message);
          setTimeout(() => {
            setError(null);
          }, 5000);
        });
    } else {
      setFormErrors(errors);
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    document.body.classList.remove("modal-open");
  };

  const handleFocus = () => {
    setPasswordFocused(true);
    if (!formValues.password) {
      setPasswordDescription("Password not entered");
    }
  };

  const handleClickOutside = (e) => {
    if (pwdStrengthWrapRef.current && !pwdStrengthWrapRef.current.contains(e.target)) {
      setPasswordFocused(false);
    }
  };

  useEffect(() => {
    if (passwordFocused) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [passwordFocused]);

  return (
    <>
    <Heder name="new account" setting={setting}/>
      <div className="advert_main_div">
        <p className="advert_heading">Registration</p>
      </div>

      <div className="container">
        <div className="register_main">
          {success && (
            <Toast
              onClose={() => setSuccess(null)}
              show={success !== null}
              delay={3000}
              autohide
              className="fade bg-success register_msg toast_message_div"
            >
              <Toast.Body>{success}</Toast.Body>
            </Toast>
          )}
          {error && (
            <Toast
              onClose={() => setError(null)}
              show={error !== null}
              delay={3000}
              autohide
              className="fade bg-danger register_msg toast_message_div"
            >
              <Toast.Body>{error}</Toast.Body>
            </Toast>
          )}
          <div className="register_info">
            <div className="register_form">
              <h1 className="register_heading">
                <div className="register_heading_div">
                  <FaUserPlus />
                  Register Your Account
                </div>
              </h1>

              <form onSubmit={handleSubmit}>
                <div className="register_radio">
                  <input
                    type="radio"
                    name="user_type"
                    value="private"
                    onChange={handleChange}
                    checked={formValues.user_type === "private"}
                    id="user_type1"
                  />
                  <label className="register_label" htmlFor="user_type1">
                    Private Ad
                  </label>
                  <input
                    type="radio"
                    name="user_type"
                    value="business"
                    onChange={handleChange}
                    checked={formValues.user_type === "business"}
                    id="user_type2"
                  />
                  <label className="register_label" htmlFor="user_type2">
                    Business Ad
                  </label>

                  {modalVisible && (
                    <div
                      className="overlay"
                      id="overlay"
                      onClick={handleCloseModal}
                    ></div>
                  )}

                  {modalVisible && (
                    <>
                      <div className="modal_register" id="myModal">
                        <p>
                          <span
                            className="close-btn"
                            onClick={handleCloseModal}
                          >
                            ×
                          </span>
                        </p>
                        <h3 className="modal_text">
                          {" "}
                          Welcome BUSINESS USERS - You now have 2 options
                        </h3>
                        <br />
                        <p className="modal_heading">
                          1. Pay As You Go - €3 per Classified Advert.
                        </p>
                        Sign up and register your account using the form. You
                        will then have the option to add credit to your account
                        via credit card or PayPal. OR
                        <p className="modal_heading">
                          <br />
                          2. Become a "Registered Business User".
                        </p>
                        Have Multiple Classified Ads PLUS a dedicated business
                        profile page indexed on Google, with your branding,
                        business details, description, map and links to your
                        socials all listed on one page.
                        <br />
                        Packages from as little as €180 + VAT annual fee
                        (Limited time offer).
                        <p className="modal_heading">
                          <br />
                          WANT TO LEARN MORE? GET IN TOUCH
                        </p>
                        <div className="modal_text_div">
                          <p className="modal_heading">Phone:</p>
                          <a
                            href="tel: 0035374 9126410"
                            className="register_link"
                          >
                            0035374 9126410
                          </a>
                        </div>
                        <div className="modal_text_div">
                          <p className="modal_heading">Text WhatsApp:</p>
                          <a
                            href="tel: 0831602031 / 00353 831602031"
                            className="register_link"
                          >
                            0831602031 / 00353 831602031
                          </a>
                        </div>
                        <div className="modal_text_div">
                          <p className="modal_heading">Email:</p>
                          <a
                            href="mailto: sales@thedealer.ie"
                            className="register_link"
                          >
                            sales@thedealer.ie
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="error-message">{formErrors.user_type}</div>
                {formValues.user_type === "business" && (
                  <div>
                    <label className="register_label company_name">
                      Company Name
                    </label>
                    <input
                      type="text"
                      className="register_input"
                      name="company_name"
                      value={formValues.company_name}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className="register_div">
                  <div className="register_left_portion">
                    <label className="register_label company_name">
                      Full Name <span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      className="register_input"
                      name="contact_name"
                      value={formValues.contact_name}
                      onChange={handleChange}
                    />

                    <div className="error-message">
                      {formErrors.contact_name}
                    </div>

                    <label className="register_label company_name">
                      Username <span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      className="register_input"
                      name="username"
                      value={formValues.username}
                      onChange={handleChange}
                    />

                    <div className="error-message">{formErrors.username}</div>

                    <label className="register_label company_name">
                      Address <span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      className="register_input"
                      name="address_one"
                      value={formValues.address_one}
                      onChange={handleChange}
                    />

                    <div className="error-message">
                      {formErrors.address_one}
                    </div>

                    <label className="register_label company_name">
                      County <span className="astrick">*</span>
                    </label>
                    <select
                      name="county_id"
                      id="county_id"
                      className="register_input"
                      value={formValues.county_id}
                      onChange={handleChange}
                    >
                      <option value="">Select County</option>
                      {counties.map((county) => (
                        <option key={county.id} value={county.id}>
                          {county.county}
                        </option>
                      ))}
                    </select>

                    <div className="error-message">{formErrors.county_id}</div>
                  </div>

                  <div className="register_right_portion">
                    <label className="register_label company_name">
                      Email Address <span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      className="register_input"
                      name="email"
                      value={formValues.email}
                      onChange={handleChange}
                    />

                    <div className="error-message">{formErrors.email}</div>

                    <label className="register_label company_name">
                      Password <span className="astrick">*</span>
                    </label>
                    <div className="password_input">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formValues.password}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onKeyUp={handleFocus}
                        className="register_input"
                      />
                      <div
                        className="password-toggle"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                      </div>
                    </div>
                    {passwordFocused && (
                      <div id="pwd_strength_wrap" className="password_tips" ref={pwdStrengthWrapRef}>
                        <p id="passwordDescription">{passwordDescription}</p>
                        <div className="password-strength-container">
                          <div
                            className={`password-strength-range strength-${passwordStrength}`}
                          ></div>
                        </div>
                        <div
                          id="passwordStrength"
                          className={`strength${passwordStrength}`}
                        ></div>
                        <strong>Strong Password Tips:</strong>
                        <ul>
                          <li
                            id="length"
                            className={
                              formValues.password.length >= 6
                                ? "valid"
                                : "invalid"
                            }
                          >
                            At least 6 characters
                          </li>
                          <li
                            id="pnum"
                            className={
                              /\d/.test(formValues.password)
                                ? "valid"
                                : "invalid"
                            }
                          >
                            At least 1 digit
                          </li>
                          <li
                            id="capital"
                            className={
                              /[A-Z]/.test(formValues.password) &&
                              /[a-z]/.test(formValues.password)
                                ? "valid"
                                : "invalid"
                            }
                          >
                            Both uppercase and lowercase letters
                          </li>
                          <li
                            id="spchar"
                            className={
                              /[!@#$%^&*?_~()-]/.test(formValues.password)
                                ? "valid"
                                : "invalid"
                            }
                          >
                            At least 1 special character
                          </li>
                        </ul>
                      </div>
                    )}
                    <div className="error-message">{formErrors.password}</div>

                    <label className="register_label company_name">
                      Primary Telephone <span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      className="register_input"
                      name="mobile"
                      value={formValues.mobile}
                      onChange={handleChange}
                      maxLength="20"
                    />

                    <div className="error-message">{formErrors.mobile}</div>

                    <label className="register_label company_name">
                      Town <span className="astrick">*</span>
                    </label>
                    <select
                      name="town_id"
                      id="town_id"
                      className="register_input"
                      value={formValues.town_id}
                      onChange={handleChange}
                    >
                      <option value="">Select Town</option>
                      {towns.map((town) => (
                        <option key={town.id} value={town.id}>
                          {town.town}
                        </option>
                      ))}
                    </select>

                    <div className="error-message">{formErrors.town_id}</div>

                    <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} className="company_name" name="recaptcha" onChange={(value) => {
    setRecaptchaValue(value);
    setError(null); // Clear the error when ReCAPTCHA is successfully checked
  }}/>

                    <label className="register_newsletter company_name">
                      <input
                        type="checkbox"
                        name="newsletter"
                        value={formValues.newsletter}
                        onChange={handleChange}
                      />
                      <span className="newsletter_word">
                        Subscribe to our Newsletter
                      </span>
                    </label>

                    <div className="terms">
                      <p>
                        In submitting this form you agree to our{" "}
                        <span className="term_word" onClick={handleTerms}>
                          terms and conditions.
                        </span>
                      </p>
                    </div>

                    <div className="submit_register">
                      <button type="submit" className="create_account_btn">
                        Create my account
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="register_suggestion">
              <div className="registration_div">
                <h1 className="register_heading_info">
                  <div className="info_register">
                    <IoCreate className="place_icon" />
                    <p>Place An Add</p>
                  </div>
                </h1>
                <p>
                  Do you have something to sell, to rent, any service to offer?
                  Post it at TheDealer, its free, for local business and very
                  easy to use!
                </p>

                <h1 className="register_heading_info">
                  <div className="info_register">
                    <FaHeart className="place_icon" />
                    <p>Create your favorite listings list.</p>
                  </div>
                </h1>
                <p>
                  Create your favorite listings list. And save your search.
                  Don't forget any deal.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Register;
