import React from 'react'

const ProductNotFound = () => {

    document.title = "Advert is deleted"

  return (
    <>
    <div className="inactive_div">
        <div className="container">
          <div className="box_inactive_main_div">
            <div className="box_inactive">
              <h1>This advert is deleted</h1>
              <p>
              This advert no longer exists in our records.
              </p>
            </div>
          </div>
        </div>
    </div>
    </>
  )
}

export default ProductNotFound