import React, { useState } from "react";
import { Button, Form, InputGroup, Nav } from "react-bootstrap";
import { FaFileAlt } from "react-icons/fa";
import { RiMessage2Fill } from "react-icons/ri";
import facebook from "../Images/fb.png";
import twitter from "../Images/twittericon.png";
import "./Footer.css";
import { Link, useNavigate } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Cookies from "js-cookie";
import appstore from "../Images/App-store.png";
import playstore from "../Images/Play-store.png";

const Footer = ({setting, balance}) => {
  const navigate = useNavigate();
  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);

  const [email, setEmail] = useState("");

  const handleContact = () => {
    navigate("/contact");
  };

  // let year = new Date().getFullYear();

  const handleMouseDown = (event) => {
    if (event.button === 1) {
      event.preventDefault();
      Cookies.remove("listingToken");
      if (tokenObject && tokenObject.user_type == "admin") {
        window.open(
          `${process.env.REACT_APP_ADMIN_APP_API_URL}/advert/index/private`,
          "_blank"
        );
      } else if (tokenObject) {
        if (balance && balance.result === true) {
          window.open("/online_ad");
        } else {
          window.open("/payments");
        }
      } else {
        window.open("/login");
      }
    }
  };

  const handlePlaceAd = (event) => {
    event.preventDefault();
    Cookies.remove("listingToken");
    if (tokenObject && tokenObject.user_type == "admin") {
      window.open(
        `${process.env.REACT_APP_ADMIN_APP_API_URL}/advert/index/private`,
        "_self"
      );
    } else if (tokenObject) {
      if (balance && balance.result === true) {
        navigate("/online_ad");
      } else {
        navigate("/payments");
      }
    } else {
      navigate("/login");
    }
  };

  const handleUpSideArrow = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const url =
    "//thedealer.us5.list-manage.com/subscribe/post?u=cf4f1174fb9e89e2f783b1622&id=3642a0ed25";

  return (
    <>
      <div className="footer_main">
        <div className="container footer_section_top">
          <div className="footer_main">
            <div className="fb-page footer_title">
              <div className="twitterfeed">
                <div id="fb-root" className="fb_reset">
                  <div
                    style={{
                      position: "absolute",
                      top: "-10000px",
                      width: "0px",
                      height: "0px",
                    }}
                  >
                    <div></div>
                  </div>
                </div>

                <div
                  Name="fb-page fb_iframe_widget"
                  data-href="https://www.facebook.com/TheDealerFreeAdsPaper/"
                  data-tabs="timeline"
                  data-width="100%"
                  data-height="320"
                  data-small-header="true"
                  data-adapt-container-width="true"
                  data-hide-cover="true"
                  data-show-facepile="true"
                  fb-xfbml-state="rendered"
                  fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=774316339338541&amp;container_width=350&amp;height=320&amp;hide_cover=true&amp;href=https%3A%2F%2Fwww.facebook.com%2FTheDealerFreeAdsPaper%2F&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;small_header=true&amp;tabs=timeline&amp;width=350"
                >
                  <span className="fb_span">
                    <iframe
                      name="fe18f1da051d454ed"
                      width="100%"
                      height="320px"
                      data-testid="fb:page Facebook Social Plugin"
                      title="fb:page Facebook Social Plugin"
                      frameborder="0"
                      allowtransparency="true"
                      allowfullscreen="true"
                      allow="encrypted-media"
                      src="https://www.facebook.com/v2.6/plugins/page.php?adapt_container_width=true&amp;app_id=774316339338541&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df67b7ff79c8a6a36b%26domain%3Dwww.thedealer.ie%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.thedealer.ie%252Ff59363590fd18530e%26relation%3Dparent.parent&amp;container_width=300&amp;height=320&amp;hide_cover=true&amp;href=https%3A%2F%2Fwww.facebook.com%2FTheDealerFreeAdsPaper%2F&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;small_header=true&amp;tabs=timeline&amp;width=350"
                      style={{
                        border: "none",
                        visibility: "visible",
                        width: "100%",
                        height: "320px",
                      }}
                      Name=""
                    ></iframe>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_main">
            <h5 className="footer_title">Need Help?</h5>
            <p className="need_help">
              We're here to answer any questions you have.
            </p>
            <div className="need_help">
              <span>
                <FaLocationDot />
              </span>
              <p>Letterkenny, Donegal</p>
            </div>
            <div className="need_help">
              <span>
                <FaFileAlt />
              </span>
              <p>
                <a href="mailto:info@thedealer.ie" className="footer_mail">
                  info@thedealer.ie
                </a>
              </p>
            </div>
            <div className="need_help">
              <span>
                <RiMessage2Fill />
              </span>
              <p>
                <a href="tel:+353749126410" className="footer_mail">
                  +353 (0)74 91 26410
                </a>
              </p>
            </div>
            <div className="social">
              <a
                href={`${setting.facebook_link}`}
                target="blank"
              >
                <img
                  src={facebook}
                  alt="facebook"
                  className="social_media_img"
                />
              </a>
              <a
                href={`${setting.twitter_link}`}
                target="blank"
              >
                <img src={twitter} alt="twitter" className="social_media_img" />
              </a>
            </div>
          </div>
          <div className="footer_main">
            <h5 className="footer_title">The Dealer.ie</h5>
            <p className="link_dealer_tag">
              <span className="hover_link">
                <span className="footer_arrow">
                  <IoIosArrowForward />
                </span>
                {/* <Link to="/online_ad" className="link_dealer"> */}
                {/* <a href="/online_ad" className="link_dealer"> */}
                <Nav.Link
                  as={Link}
                  className="link_dealer"
                  onClick={handlePlaceAd}
                  onMouseDown={handleMouseDown}
                >
                  {/* <span className="link_dealer" onClick={handlePlaceAd}> */}{" "}
                  Place an Ad {/* </span> */}
                </Nav.Link>
                {/* </a> */}
                {/* </Link> */}
              </span>
            </p>
            <p className="link_dealer_tag">
              <span className="hover_link">
                <span className="footer_arrow">
                  <IoIosArrowForward />
                </span>
                <Link
                  to="/business_directory/search/s/all/location/all"
                  className="link_dealer"
                >
                  {/* <a href="/business_directory/search/s/all/location/all" className="link_dealer"> */}{" "}
                  Find Businesses
                  {/* </a> */}
                </Link>
              </span>
            </p>
            <p className="link_dealer_tag">
              <span className="hover_link">
                <span className="footer_arrow">
                  <IoIosArrowForward />
                </span>
                <Link to="/terms_and_conditions" className="link_dealer">
                  {/* <a href="/terms_and_conditions" className="link_dealer"> */}{" "}
                  Terms & Conditions
                  {/* </a> */}
                </Link>
              </span>
            </p>
            <p className="link_dealer_tag">
              <span className="hover_link">
                <span className="footer_arrow">
                  <IoIosArrowForward />
                </span>
                <Link to="/faq" className="link_dealer">
                  {/* <a href="/faq" className="link_dealer"> */} FAQ
                  {/* </a> */}
                </Link>
              </span>
            </p>
            <p className="link_dealer_tag">
              <span className="hover_link">
                <span className="footer_arrow">
                  <IoIosArrowForward />
                </span>
                <Link to="/about" className="link_dealer">
                  {/* <a href="/about" className="link_dealer"> */} About
                  {/* </a> */}
                </Link>
              </span>
            </p>
            <p className="link_dealer_tag">
              <span className="hover_link">
                <span className="footer_arrow">
                  <IoIosArrowForward />
                </span>
                <Link to="/contact" className="link_dealer">
                  {/* <a href="/contact" className="link_dealer"> */} Get in
                  Touch {/* </a> */}
                </Link>
              </span>
            </p>
          </div>
          <div className="footer_main">
            <h5 className="footer_title">Sign up to get the latest news</h5>
            <div>
              {" "}
              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                  <Form inline>
                    <InputGroup>
                      <Form.Control
                        placeholder="Email Address"
                        aria-label="email"
                        aria-describedby="basic-addon1"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Button
                        className="register_footer"
                        onClick={() => subscribe({ EMAIL: email })}
                      >
                        Sign Up
                      </Button>
                    </InputGroup>

                    {status === "sending" && <p>{message}</p>}
                    {status === "error" && (
                      <p className="error-message">
                        {message == "0 - Please enter a value"
                          ? "Email is required"
                          : message}
                      </p>
                    )}
                    {status === "success" && (
                      <p className="success-message">{message}</p>
                    )}
                    <Button className="feedback_btn" onClick={handleContact}>
                      Give us your Feedback
                    </Button>
                  </Form>
                )}
              />
            </div>

            <div className="social_media_footer">
              <a
                href={`${setting.ios_store_link}`}
                target="blank"
              >
                <img
                  src={appstore}
                  alt="appstore"
                />
              </a>
              <a
                href={`${setting.play_store_link}`}
                target="blank"
              >
                <img src={playstore} alt="playstore"/>
              </a>
            </div>
          </div>
        </div>

        <div className="footer_div">
          <div className="copyright">
            <span  dangerouslySetInnerHTML={{ __html: setting.copyright }}
        style={{ textAlign: 'center', marginTop: '20px' }}>
      </span>
            <IoIosArrowUp
              className="upside_arrow_icon"
              onClick={handleUpSideArrow}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
