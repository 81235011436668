import React, { useState } from "react";
import "./Login.css";
import Footer from "./Footer";
import axios from "axios";
import { DealerApi } from "./Api";
import { Alert } from "react-bootstrap";
import Cookies from "js-cookie";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Heder from "./Heder";


const DeleteAccount = ({onLogout, setting}) => {

  const [showPassword, setShowPassword] = useState(false);
  const initialValues = {
    emailOrUsername: "",
    password: "",
  };
  const [loginformValues, setLoginFormValues] = useState(initialValues);
  const [loginformErrors, setLoginFormErrors] = useState({});
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginFormValues({ ...loginformValues, [name]: value });
    setLoginFormErrors({ ...loginformErrors, [name]: "" });
  };

  const validateForm = () => {
    let errors = {};
    if (!loginformValues.emailOrUsername) {
      errors.emailOrUsername = "Email or User Name is required";
    }
    if (!loginformValues.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      axios
        .post(`${DealerApi}/user/delete/account`, loginformValues, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
            setSuccess(res.data.body.message);
            Cookies.remove("loginToken");
            Cookies.remove("listingToken");
            onLogout();
            setTimeout(() => {
                setLoginFormValues(initialValues)
                setSuccess(null);
              }, 5000);
       
        })
        .catch((error) => {
          setSuccess(null);
          setError(error.response.data.body.message);
          setTimeout(() => {
            setLoginFormValues(initialValues)
            setError(null);
          }, 5000);
        });
    } else {
      setLoginFormErrors(errors);
    }
  };


  
  return (
    <>
    <Heder name="Delete Account" setting={setting}/>
      <div className="advert_main_div">
        <p className="advert_heading">Delete Account</p>
      </div>

      <div className="container">
        <div className="login_main">
          <div className="login_form delete_form">
            {success && (
              <Alert variant="success" style={{ fontSize: "20px" }}>
                {success}
              </Alert>
            )}
            {error && (
              <Alert variant="danger" style={{ fontSize: "20px" }}>
                {error}
              </Alert>
            )}

            <form onSubmit={handleSubmit}>
              <label className="login_label">
              Email or Username <span className="astrick">*</span>
              </label>
              <input
                type="text"
                className="login_input"
                name="emailOrUsername"
                value={loginformValues.emailOrUsername}
                onChange={handleChange}
              />
              <div className="error-message">{loginformErrors.emailOrUsername}</div>

              <label className="login_label user_name">
                Password <span className="astrick">*</span>
              </label>
              <div className="password_input">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={loginformValues.password}
                  onChange={handleChange}
                  className="login_input"
                />
                <div
                  className="password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                </div>
              </div>

              <div className="error-message">{loginformErrors.password}</div>

            

              <div className="submit_login delete_login">
                <button type="submit" className="login_btn">
                  Submit
                </button>
              </div>
            </form>
           
          </div>
        </div>
      </div>

    </>
  );
};

export default DeleteAccount;
