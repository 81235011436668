import React, { useEffect, useState } from "react";
import "./ImageUpload.css";
import { AiTwotonePicture } from "react-icons/ai";
import { FaArrowsRotate } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Button, Table, Toast } from "react-bootstrap";
import axios from "axios";
import { DealerApi } from "./Api";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Heder from "./Heder";

const ImageUpload = ({setting}) => {
  const { listing_id } = useParams();
  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);
  const listing = Cookies.get("listingToken");
  const listingObject = listing && JSON.parse(listing);
  const [images, setImages] = useState([]);
  const [rotationDegrees, setRotationDegrees] = useState([]);
  const [listImages, setListImages] = useState([]);
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [deleteError, setDeleteError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [imageLimitReached, setImageLimitReached] = useState(false);

  const navigate = useNavigate();

  const handleDivClick = () => {
    document.getElementById("fileInput").click();
  };

  useEffect(() => {
    handleListing();
  }, []);

  const handleListing = () => {
    setLoad(true);
    if (listingObject) {
      axios
        .get(
          `${DealerApi}/listing/image/${
            listingObject && listingObject.listing_id
          }`,
          {
            headers: {
              Authorization: `Bearer ${tokenObject && tokenObject.token}`,
            },
          }
        )
        .then((res) => {
          setLoad(false);
          setListImages(res.data.body.result);
          setApiDataLoaded(true);
        })
        .catch((err) => {
          setLoad(false);
        });
    }
  };

  const handleImageChange = (event) => {
    setLoad(true);
    const files = event.target.files;
    const totalImages = listImages.length + files.length;
  
    if (totalImages > 10) {
      setImageLimitReached(true);
      setLoad(false);
      return;
    } else {
      setImageLimitReached(false);
    }
  
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("images", files[i]);
    }
  
    formData.append("listing_id", listingObject.listing_id);
  
    axios
      .post(`${DealerApi}/listing/image/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokenObject.token}`,
        },
      })
      .then((response) => {
        handleListing();
        setLoad(false);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.body.message);
          setDeleteError(true);
          setTimeout(() => {
            setDeleteError(false);
          }, 2000);
        } else {
          setErrorMessage("An unexpected error occurred. Please try again.");
          setDeleteError(true);
        }
        setLoad(false);
      })
      .finally(() => {
        setLoad(false);
      });
  };  

  const handleRotate = (index, rotate) => {
    const image_id = listImages[index].id;
    if (rotate < 270) {
      rotate += 90;
    } else {
      rotate = 0;
    }
    handleRotateAPI(image_id, rotate);
  };

  const handleRotateAPI = (image_id, rotationDegree) => {
    axios
      .patch(
        `${DealerApi}/listing/image/rotate`,
        { image_id: image_id, rotate: rotationDegree },
        {
          headers: {
            Authorization: `Bearer ${tokenObject.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        handleListing();
      })
      .catch((error) => {});
  };

  const handleRemove = (index) => {
    setLoad(true);
    const imageIdToRemove = listImages[index].id;
    axios
      .delete(
        `${DealerApi}/listing/image/delete/${listingObject.listing_id}/${imageIdToRemove}`,
        {
          headers: {
            Authorization: `Bearer ${tokenObject.token}`,
          },
        }
      )
      .then((response) => {
        // window.location.reload();
        const newImages = [...images];
        const newRotationDegrees = [...rotationDegrees];
        newImages.splice(index, 1);
        newRotationDegrees.splice(index, 1);

        setImages(newImages);
        setRotationDegrees(newRotationDegrees);

        handleListing();
      })
      .catch((error) => {});
  };

  const handleSubmit = () => {
    const values = {};

    if(listingObject.listing_id == listing_id) {
      values.finish_from = "edit"; 
    } else {
      values.finish_from = "add";
    }

    if(listingObject) {
      setLoading(true);
      axios
        .post(
          `${DealerApi}/listing/finish/email/${listingObject.listing_id}`,
          values,
          {
            headers: {
              Authorization: `Bearer ${tokenObject.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.body.success === 1) {
            setSuccessMessage(res.data.body.message);
            setDeleteSuccess(true);
          } else {
            setErrorMessage(res.data.body.message);
            setDeleteError(true);
          }
  
          setTimeout(() => {
            navigate("/my_online_ads");
          }, 1000);
        })
        .catch((err) => {
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            setDeleteSuccess(false);
            setDeleteError(false);
          }, 3000);
        });
    }
  };

  const handlePrevious = () => {
    navigate("/online_ad");
  };

  useEffect(() => {
    if (!listingObject) {
      navigate("/online_ad");
    } else {
      setApiDataLoaded(true);
    }
  }, [apiDataLoaded, listingObject]);

  return (
    <>
      <Heder name="online ad form" setting={setting}/>
      {!listingObject ? null : (
        <>
          <div className="advert_main_div">
            <p className="advert_heading">Upload Picture</p>
          </div>
          <div className="container">
            {imageLimitReached && (
              <Toast className="fade bg-danger toast_message_div">
                <Toast.Body className="toast_message">
                  You can only upload up to 10 images.
                </Toast.Body>
              </Toast>
            )}
            <Toast
              className="fade bg-success toast_message_div mt-3"
              show={deleteSuccess}
              onClose={() => setDeleteSuccess(false)}
            >
              <Toast.Body className="toast_message">
                {successMessage}
              </Toast.Body>
            </Toast>
            <Toast
              show={deleteError}
              onClose={() => setDeleteError(false)}
              className="fade bg-danger toast_message_div"
            >
              <Toast.Body className="toast_message">{errorMessage}</Toast.Body>
            </Toast>
            <div className="upload_image_div">
              <div className="upload_image_content">
                <AiTwotonePicture className="photo_icon" />
                <div>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                    multiple
                  />
                  <button
                    type="button"
                    className="add_photo"
                    onClick={handleDivClick}
                    disabled={listImages.length >= 10}
                  >
                    Add Photos
                  </button>
                </div>
              </div>

              <div className="load_img_div">
                {load && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    className="loader_img"
                  />
                )}
              </div>
              {listImages &&
                listImages.map((image, index) => (
                  <div key={index}>
                    <Table striped>
                      <tbody>
                        <tr>
                          <td className="img_tab">
                            <img
                              src={image.picture.url.small}
                              alt="img"
                              style={{
                                transform: `rotate(${image.rotate}deg)`,
                              }}
                              className="user_upload_img"
                            />
                          </td>
                          <td>
                            <div className="rotate_btn_div">
                              <Button
                                variant="success"
                                title="Rotate"
                                onClick={() =>
                                  handleRotate(index, image.rotate)
                                }
                              >
                                <FaArrowsRotate />
                              </Button>
                              <Button
                                variant="danger"
                                title="Delete"
                                onClick={() => handleRemove(index)}
                              >
                                <RiDeleteBin6Line />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ))}

              <div className="prev_div">
                <button
                  type="button"
                  className="prev_btn"
                  onClick={handlePrevious}
                >
                  Previous Page
                </button>
                <button
                  type="button"
                  className="prev_btn"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <FontAwesomeIcon icon={faSpinner} spin /> Finishing...
                    </>
                  ) : (
                    "Finish"
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ImageUpload;
