import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./Page/Home";
import Register from "./Component/Register";
import Login from "./Component/Login";
import Faq from "./Page/Faq";
import TermsCondition from "./Page/TermsCondition";
import Contact from "./Page/Contact";
import About from "./Page/About";
import ForgetPswd from "./Component/ForgetPswd";
import CategoryPage from "./Component/CategoryPage";
import VerifyAccount from "./Component/VerifyAccount";
import LoginVerify from "./Component/LoginVerify";
import PrivateRoute from "./Component/PrivateRouting";
import ImageUpload from "./Component/ImageUpload";
import BusinessDirectory from "./Page/BusinessDirectory";
import Wishlist from "./Page/Wishlist";
import MyAd from "./Page/MyAd";
import EditAccount from "./Page/EditAccount";
import Dashboard from "./Page/Dashboard";
import Search from "./Component/Search";
import MainNavbar from "./Component/MainNavbar";
import OptionNavbar from "./Component/OptionNavbar";
import React, { useEffect, useState } from "react";
import SingleProduct from "./Component/SingleProduct";
import ReportPage from "./Component/ReportPage";
import PlaceAdd from "./Component/PlaceAdd";
import SavedSearch from "./Page/SavedSearch";
import BusinessDirectoryDetail from "./Page/BusinessDirectoryDetail";
import AdInactive from "./Page/AdInactive";
import BusinessDirectoryInfo from "./Page/BusinessDirectoryInfo";
import Paypal from "./Page/Paypal";
import Payment from "./Page/Payment";
import ScrollToTopOnPageChange from "./Component/ScrollToTopOnPageChange";
import CookiesPage from "./Component/Cookies";
import Cookies from "js-cookie";
import CategorySearch from "./Page/CategorySearch";
import CarSearch from "./Page/CarSearch";
import StatusUpdate from "./Page/StatusUpdate";
import axios from "axios";
import { DealerApi } from "./Component/Api";
import DeleteAccount from "./Component/DeleteAccount";
import Footer from "./Component/Footer";

function App() {
  const [loginStatus, setLoginStatus] = useState(false);
  const [setting, setSetting] = useState([]);
  const [balance, setBalance] = useState([]);
  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);

  useEffect(() => {
    axios
      .get(`${DealerApi}/settings`)
      .then((res) => {
        setSetting(res.data.body.result[0]);
        const analyticsCode = res.data.body.result[0].analytics_code;

        injectScripts(analyticsCode);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (tokenObject) {
      axios
        .get(`${DealerApi}/user/check/placeanadd/${tokenObject.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenObject.token}`,
          },
        })
        .then((res) => {
          setBalance(res.data.body);
        })
        .catch((err) => {});
    }
  }, [loginStatus]);

  const injectScripts = (analyticsCode) => {
    const parser = new DOMParser();
    const htmlDocument = parser.parseFromString(analyticsCode, "text/html");

    const scriptTags = htmlDocument.querySelectorAll("script");

    scriptTags.forEach((scriptTag) => {
      const newScript = document.createElement("script");

      if (scriptTag.src) {
        newScript.src = scriptTag.src;
        newScript.async = true;
      } else {
        newScript.innerHTML = scriptTag.innerHTML;
      }

      document.head.appendChild(newScript);
    });

    const noscriptTags = htmlDocument.querySelectorAll("noscript");
    noscriptTags.forEach((noscriptTag) => {
      const newNoscript = document.createElement("noscript");
      newNoscript.innerHTML = noscriptTag.innerHTML;
      document.head.appendChild(newNoscript);
    });
  };

  useEffect(() => {
    const storedLoginStatus = Cookies.get("loginStatusTheDealer");
    if (storedLoginStatus) {
      setLoginStatus(JSON.parse(storedLoginStatus));
    }
  }, []);

  useEffect(() => {
    Cookies.set("loginStatusTheDealer", JSON.stringify(loginStatus));
  }, [loginStatus]);

  const handleLogin = () => {
    setLoginStatus(true);
  };

  const handleLogout = () => {
    setLoginStatus(false);
    setBalance([]);
  };

  return (
    <>
      <div className="header_main">
        <MainNavbar
          loginStatus={loginStatus}
          onLogout={handleLogout}
          balance={balance}
        />
        <OptionNavbar balance={balance} />
      </div>
      <ScrollToTopOnPageChange />
      <Routes>
        <Route
          path="/"
          element={<Home setting={setting} balance={balance} />}
        />
        <Route path="/register" element={<Register setting={setting} />} />

        {/* <Route path={"/login"} element={<Login onLogin={handleLogin} />} /> */}
        <Route
          path={"/login"}
          element={
            loginStatus ? (
              <Navigate to="/client" />
            ) : (
              <Login onLogin={handleLogin} setting={setting} />
            )
          }
        />
        <Route
          path="/login/forgot-password"
          element={<ForgetPswd setting={setting} />}
        />
        <Route
          path="/verify_account"
          element={<VerifyAccount setting={setting} />}
        />
        <Route
          path="/active/:token"
          element={<LoginVerify />}
        />
        <Route path="/faq" element={<Faq setting={setting} />} />
        <Route
          path="/terms_and_conditions"
          element={<TermsCondition setting={setting} />}
        />
        <Route path="/about" element={<About setting={setting} />} />
        <Route path="/contact" element={<Contact setting={setting} />} />
        <Route path="/cookies" element={<CookiesPage setting={setting} />} />
        <Route
          path="/:category/:subcategory/:county/:adtype/:id/:slug"
          element={<SingleProduct />}
        />
        <Route
          path="/report_an_advert/:category/:subcategory/:county/:adtype/:id/:slug"
          element={<ReportPage setting={setting} />}
        />
        <Route
          path="/report_an_advert"
          element={<ReportPage setting={setting} />}
        />

        <Route
          path="/online_ad"
          element={
            <PrivateRoute>
              <PlaceAdd setting={setting} />
            </PrivateRoute>
          }
        />
        {/* <Route path="/online_ad/uploads" element={<PrivateRoute><ImageUpload /></PrivateRoute>} />
        <Route path="/online_ad/uploads/:listing_id" element={<PrivateRoute><ImageUpload /></PrivateRoute>} /> */}
        <Route
          path="/online_ad/uploads/:listing_id?"
          element={
            <PrivateRoute>
              <ImageUpload setting={setting} />
            </PrivateRoute>
          }
        />

        <Route path="/search" element={<CategorySearch setting={setting} />} />
        <Route
          path="/search/all"
          element={<CategorySearch setting={setting} />}
        />
        <Route path="/:slug" element={<CategoryPage />} />
        <Route
          path="/:slug/:subcat"
          element={<CategoryPage />}
        />

        <Route path="/car_search" element={<CarSearch setting={setting} />} />

        <Route
          path="/search/search_lookfor"
          element={<Search setting={setting} />}
        />

        <Route
          path="/business_directory/search/s/all/location/:all"
          element={<BusinessDirectory setting={setting} />}
        />
        <Route
          path="/business_directory/*"
          element={
            <Navigate
              to="/business_directory/search/s/all/location/all"
              replace
            />
          }
        />
        <Route
          path="/delete-account"
          element={<DeleteAccount onLogout={handleLogout} setting={setting} />}
        />

        <Route
          path="/my_online_ads"
          element={
            <PrivateRoute>
              <MyAd setting={setting} balance={balance} />
            </PrivateRoute>
          }
        />
        <Route
          path="/my_online_ads/status/:id/inactive"
          element={
            <PrivateRoute>
              <StatusUpdate setting={setting} />
            </PrivateRoute>
          }
        />
        <Route
          path="/watchlist"
          element={
            <PrivateRoute>
              <Wishlist setting={setting} />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit_account"
          element={
            <PrivateRoute>
              <EditAccount setting={setting} />
            </PrivateRoute>
          }
        />
        <Route
          path="/client"
          element={
            <PrivateRoute>
              <Dashboard setting={setting} balance={balance} />
            </PrivateRoute>
          }
        />
        <Route
          path="/saved_search"
          element={
            <PrivateRoute>
              <SavedSearch setting={setting} />
            </PrivateRoute>
          }
        />
        <Route
          path="/business_directory/details/:id/:slug"
          element={<BusinessDirectoryDetail />}
        />
        <Route path="/ad_inactive" element={<AdInactive setting={setting} />} />
        <Route
          path="/business_directory/info"
          element={<BusinessDirectoryInfo setting={setting} />}
        />
        <Route
          path="/payments"
          element={
            <PrivateRoute>
              <Payment setting={setting} balance={balance} />
            </PrivateRoute>
          }
        />
        <Route
          path="/payments/paypal"
          element={
            <PrivateRoute>
              <Paypal setting={setting} />
            </PrivateRoute>
          }
        />
      </Routes>

      <Footer setting={setting} balance={balance} />
    </>
  );
}

export default App;
