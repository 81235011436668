import React from "react";
import { Helmet } from "react-helmet";

const Heder = ({ name, setting }) => {
  return (
    <>
      {setting && (
        <Helmet>
          <title>{`${name.charAt(0).toUpperCase() + name.slice(1)} - ${
            setting.meta_title
          }`}</title>
          <meta
            name="description"
            content={`${name} - ${setting.meta_description}`}
          />
          <meta
            name="keywords"
            content={`${name} - ${setting.meta_keywords}`}
          />
        </Helmet>
      )}
    </>
  );
};

export default Heder;
