import React, { useEffect, useState } from "react";
import "./SavedSearch.css";
import { IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import { DealerApi } from "../Component/Api";
import Cookies from "js-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Alert, Toast } from "react-bootstrap";
import Pagination from "../Component/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Heder from "../Component/Heder";

const SavedSearch = ({setting}) => {
  const navigate = useNavigate();

  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);
  const [searchData, setSearchData] = useState([]);
  const [status, setStatus] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get("page")) || 1;

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState();
  const [load, setLoad] = useState(true);

  const firstGetData = async () => {
    setLoad(true);
    await axios
      .get(
        `${DealerApi}/search/get/${tokenObject.id}?per_page=${perPage}&page_no=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${tokenObject.token}`,
          },
        }
      )
      .then((response) => {
        setLoad(false);
        if (response.data.body.result.history.length == 0) {
          setSearchData([]);
          setToastMessage("");
          setTimeout(() => {
            setStatus(true);
          }, 1000);
        } else {
          setSearchData(response.data.body.result.history);
          const calculatedTotalPages = Math.ceil(
            response.data.body.result.total / perPage
          );
          setTotalPages(calculatedTotalPages);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  useEffect(() => {
    firstGetData();
  }, [currentPage]);

  const handleView = (item) => {
    if (item.search_key_word == "") {
      if (
        item.filters.subcategory_slug == "cars" ||
        item.filters.subcategory_slug == "4x4-wheel-drive"
      ) {
        navigate(
          `/car_search?cat=${
            item.filters.category_slug == ""
              ? "all"
              : item.filters.category_slug
          }&sub_cat=${
            item.filters.subcategory_slug == ""
              ? "all"
              : item.filters.subcategory_slug
          }&county=${
            item.filters.county_slug == "" ? "all" : item.filters.county_slug
          }&ad_type=${
            item.filters.ad_type == "" ? "all" : item.filters.ad_type
          }&make=${
            item.filters.make_slug == "" ? "all" : item.filters.make_slug
          }&model=${
            item.filters.model_slug == "" ? "all" : item.filters.model_slug
          }&price_from=${
            item.filters.min_price == "0"
              ? "0"
              : item.filters.min_price == ""
              ? "all"
              : item.filters.min_price
          }&price_to=${
            item.filters.max_price == "0"
              ? "0"
              : item.filters.max_price == ""
              ? "all"
              : item.filters.max_price
          }&fuel=${
            item.filters.fuel == "" ? "all" : item.filters.fuel
          }&body_type=${
            item.filters.body_type == "" ? "all" : item.filters.body_type
          }&colour=${
            item.filters.colour == "" ? "all" : item.filters.colour
          }&engine_size=${
            item.filters.engine_size == "" ? "all" : item.filters.engine_size
          }&min_year=${
            item.filters.min_year == "" ? "all" : item.filters.min_year
          }&max_year=${
            item.filters.max_year == "" ? "all" : item.filters.max_year
          }`
        );
      } else {
        navigate(
          `/search?cat=${
            item.filters.category_slug == ""
              ? "all"
              : item.filters.category_slug
          }&sub_cat=${
            item.filters.subcategory_slug == ""
              ? "all"
              : item.filters.subcategory_slug
          }&county=${
            item.filters.county_slug == "" ? "all" : item.filters.county_slug
          }&ad_type=${
            item.filters.ad_type == "" ? "all" : item.filters.ad_type
          }`
        );
      }
    } else {
      navigate(`/search/search_lookfor?q=${item.search_key_word}`);
    }
  };

  const handleDeleteSearch = (item) => {
    axios
      .delete(`${DealerApi}/search/delete/${item.id}`, {
        headers: {
          Authorization: `Bearer ${tokenObject.token}`,
        },
      })
      .then((res) => {
        firstGetData();
        setToastMessage("Successfully deleted from search page");
        setTimeout(() => {
          setToastMessage("");
        }, 2000);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (currentPage !== initialPage) {
      setSearchParams({ page: currentPage });
    }
  }, [currentPage, initialPage, setSearchParams]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const capitalizeWord = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  return (
    <>
    <Heder name="Search history" setting={setting}/>
      <div className="advert_main_div">
        <p className="advert_heading">Search History</p>
      </div>

      <div className="container">
        <div className="load_img_div">
          {load && (
            <FontAwesomeIcon icon={faSpinner} spin className="loader_img" />
          )}
        </div>
        <div className="save_search_div">
          {status && (
            <div className="category_error_message">
              <Alert variant="primary">No search history found.</Alert>
            </div>
          )}

          <Toast
            show={toastMessage !== ""}
            onClose={() => setToastMessage("")}
            className="fade bg-danger toast_message_div"
          >
            <Toast.Body className="toast_message">{toastMessage}</Toast.Body>
          </Toast>
          {searchData && searchData.length > 0 && (
            <div className="search_table_div">
              {searchData.map((item) => {
                return (
                  <>
                    <div className="search_main_div">
                      <div className="search_data">
                        <p>
                          {" "}
                          <IoIosArrowForward />
                          {item.search_key_word && (
                            <span>
                              <span className="search_label">
                                Search Keyword:
                              </span>{" "}
                              {capitalizeWord(item.search_key_word)}
                            </span>
                          )}
                          {item.filters && item.filters.category && (
                            <span className="search_item">
                              <span className="search_label">Category:</span>{" "}
                              {item.filters.category}
                            </span>
                          )}
                          {item.filters && item.filters.subcategory && (
                            <span className="search_item">
                              <span className="search_label">Subcategory:</span>{" "}
                              {capitalizeWord(item.filters.subcategory)}
                            </span>
                          )}
                          {item.filters && item.filters.county && (
                            <span className="search_item">
                              <span className="search_label">County:</span>{" "}
                              {item.filters.county}
                            </span>
                          )}
                          {item.filters && item.filters.ad_type && (
                            <span className="search_item">
                              <span className="search_label">Account:</span>{" "}
                              {capitalizeWord(item.filters.ad_type)}
                            </span>
                          )}
                          {item.filters && item.filters.make && (
                            <span className="search_item">
                              <span className="search_label">Make:</span>{" "}
                              {item.filters.make}
                            </span>
                          )}
                          {item.filters && item.filters.model && (
                            <span className="search_item">
                              <span className="search_label">Model:</span>{" "}
                              {item.filters.model}
                            </span>
                          )}
                          {item.filters && item.filters.fuel && (
                            <span className="search_item">
                              <span className="search_label">Fuel:</span>{" "}
                              {capitalizeWord(item.filters.fuel)}
                            </span>
                          )}
                          {item.filters && item.filters.colour && (
                            <span className="search_item">
                              <span className="search_label">Colour:</span>{" "}
                              {capitalizeWord(item.filters.colour)}
                            </span>
                          )}
                          {item.filters && item.filters.body_type && (
                            <span className="search_item">
                              <span className="search_label">BodyType:</span>{" "}
                              {capitalizeWord(item.filters.body_type)}
                            </span>
                          )}
                          {item.filters && item.filters.engine_size && (
                            <span className="search_item">
                              <span className="search_label">EngineSize:</span>{" "}
                              {item.filters.engine_size}
                            </span>
                          )}
                          {item.filters &&
                            item.filters.min_year !== "" &&
                            item.filters.min_year != null && (
                              <span className="search_item">
                                <span className="search_label">Min Year:</span>{" "}
                                {item.filters.min_year}
                              </span>
                            )}
                          {item.filters &&
                            item.filters.max_year !== "" &&
                            item.filters.max_year != null && (
                              <span className="search_item">
                                <span className="search_label">Max Year:</span>{" "}
                                {item.filters.max_year}
                              </span>
                            )}
                          {item.filters &&
                            item.filters.min_price !== "" &&
                            item.filters.min_price != null && (
                              <span className="search_item">
                                <span className="search_label">Min Price:</span>{" "}
                                {item.filters.min_price}
                              </span>
                            )}
                          {item.filters &&
                            item.filters.max_price !== "" &&
                            item.filters.max_price != null && (
                              <span className="search_item">
                                <span className="search_label">Max Price:</span>{" "}
                                {item.filters.max_price}
                              </span>
                            )}
                        </p>
                      </div>
                      <div className="search_btn">
                        <button
                          className="login_btn"
                          onClick={() => handleView(item)}
                        >
                          View
                        </button>
                        <button
                          className="login_btn"
                          onClick={() => handleDeleteSearch(item)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          )}
        </div>

        {totalPages > 1 && (
          <div className="pagination">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>

    </>
  );
};

export default SavedSearch;
