import React from "react";
import "./VerifyAccount.css";
import Heder from "./Heder";

const VerifyAccount = ({setting}) => {
  return (
    <>
    <Heder name="verify acoount" setting={setting}/>
      <div className="advert_main_div">
        <p className="advert_heading">Verify Account</p>
      </div>

      <div className="forget_pswd_main">
        <div className="container">
          <div className="verify_form">
            <h1 className="forgotpswd_heading">Check Your Email</h1>

            <div className="verify_form_div">
              <h5>You must validate your account before you can sign in.</h5>
              <p>
                Validate your account my clicking the link in the email we sent
                you. If you don't see our email, please be sure to check your
                junk/spam folder.
              </p>
              <h4>Thanks,</h4>
              <h4>The Dealer Team</h4>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default VerifyAccount;