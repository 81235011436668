import React from "react";
import "./Cookies.css";
import cookies from "../Images/cookie.png";
import Heder from "./Heder";

const CookiesPage = ({setting}) => {

  return (
    <>
      <Heder name="cookies" setting={setting}/>
      <div className="advert_main_div">
        <p className="advert_heading">Cookies Policy</p>
      </div>
      <div className="container">
      <div className="box_shadow_cookies row">
        <div className="col-md-5 cookies_icon_div">
          <img src={cookies} alt="cookies"/>
        </div>
        <div className="col-md-7 cookies_information">
          <h3>What are Cookies and why do we use them?</h3>
          <p>
            Cookies are a standard method of registering user preferences when
            people use websites, to ensure a better and more personalised
            experience. These preferences are stored in a file on your
            computer's hard disk drive. For example, a cookie would contain your
            preferred country of residence so we do not have to ask you this
            question each time you visit.
          </p>
          <h3>How to Clear Cookies?</h3>
          <p>
            It is sometimes necessary to clear cookies. Click on your browser
            below to find out the steps on how to clear your cookies.
          </p>
          <ul className="unorder_cookies">
            <li>
              <a
                href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored#w_delete-cookies-for-a-single-site"
                target="blank"
                className="link_cookies"
              >
                On Firefox
              </a>
            </li>
            <li>
              <a
                href="http://windows.microsoft.com/en-IE/internet-explorer/delete-manage-cookies#ie=ie-11"
                target="blank"
                className="link_cookies"
              >
                On Windows
              </a>
            </li>
            <li>
              <a
                href="https://support.google.com/chrome/answer/95647?hl=en"
                target="blank"
                className="link_cookies"
              >
                On Google Chrome
              </a>
            </li>
            <li>
              <a
                href="http://www.opera.com/help/tutorials/security/privacy/"
                target="blank"
                className="link_cookies"
              >
                On Opera
              </a>
            </li>
            <li>
              <a
                href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
                target="blank"
                className="link_cookies"
              >
                On Safari
              </a>
            </li>
          </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookiesPage;