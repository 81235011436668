import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { DealerApi } from "../Component/Api";
import axios from "axios";
import {
  Accordion,
  Breadcrumb,
  Carousel,
  Dropdown,
  Offcanvas,
} from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import CategoryProductCard from "../Component/CategoryProductCard";
import { LiaThListSolid } from "react-icons/lia";
import { LuGrid } from "react-icons/lu";
import Cookies from "js-cookie";
import CategoryListing from "../Component/CategoryListing";
import Pagination from "../Component/Pagination.jsx";
import { Toast } from "react-bootstrap";
import megaphone from "../Images/megaphone-icon.png";
import { Helmet } from "react-helmet";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CategorySearch = ({setting}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const slug = queryParams.get("cat") == null ? "all" : queryParams.get("cat");
  const subcat =
    queryParams.get("sub_cat") == null ? "all" : queryParams.get("sub_cat");
  const countyUrl =
    queryParams.get("county") == null ? "all" : queryParams.get("county");
  const adType =
    queryParams.get("ad_type") == null ? "all" : queryParams.get("ad_type");
  const pageno = queryParams.get("page");
  const navigate = useNavigate();

  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);

  const [category, setCategory] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [account, setAccount] = useState([]);
  const [county, setCounty] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedCounty, setSelectedCounty] = useState("");

  const [selectedCategoryId, setSelectedCategoryId] = useState(slug);

  const [banner, setBanner] = useState([]);

  const [error, setError] = useState(null);

  const [sortingOption, setSortingOption] = useState("desc_date"); // Initial sorting option
  const [grid, setGrid] = useState(true);
  const [isGrid, setIsGrid] = useState(false);

  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    pageno === null || pageno === undefined || pageno === ""
      ? 1
      : parseInt(pageno)
  );
  const [totalAds, setTotalAds] = useState();
  const [perPage, setPerPage] = useState(16);
  const [totalPages, setTotalPages] = useState();

  const [wishlist, setWishlist] = useState([]);

  const [title, setTitle] = useState("");

  const [showCategoryAccordions, setShowCategoryAccordions] = useState(true);

  const [toastMessage, setToastMessage] = useState("");

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const [searchCategory, setSearchCategory] = useState("");
  const [searchSubCategory, setSearchSubCategory] = useState("");
  const [searchAccount, setSearchAccount] = useState("");
  const [searchCounty, setSearchCounty] = useState("");

  const handleShowOffcanvas = () => setShowOffcanvas(true);
  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const [saveSearch, setSaveSearch] = useState(false);

  const [categorySlug, setCategorySlug] = useState("");
  const [subCategorySlug, setSubCategorySlug] = useState("");
  const [countySlug, setCountySlug] = useState("");
  const [accountSlug, setAccountSlug] = useState("");
  const [load, setLoad] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [subId, setSubId] = useState(slug);

  if (selectedSubCategory) {
    document.title = `${selectedSubCategory} - thedealer.ie your one stop shop for buying or selling goods online in Ireland, Northern Ireland`;
  } else {
    document.title = `${selectedCategory} For Sale in Ireland - TheDealer.ie`;
  }

  useEffect(() => {
    setCategorySlug(slug);
    setSubCategorySlug(subcat);
    setCountySlug(countyUrl);
    setAccountSlug(adType);
  }, []);

  const toggleGrid = () => {
    setIsGrid(false);
    setGrid(true);
  };

  const toggleList = () => {
    setIsGrid(true);
    setGrid(false);
  };

  const handleShowPerPage = (value) => {
    setPerPage(value);
    setCurrentPage(1);
    navigate(
      `/search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&page=1`
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(
      `/search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&page=${page}`
    );
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageFromUrl = parseInt(searchParams.get("page")) || 1;

    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, currentPage]);

  useEffect(() => {
    axios
      .get(
        `${DealerApi}/filter/all?category_slug=${
          slug == "newest_listing" ? "" : slug == "all" ? "" : slug
        }&subcategory_slug=${subcat == "all" ? "" : subcat}&county_slug=${
          countyUrl == "all" ? "" : countyUrl
        }&ad_type=${adType == "all" ? "" : adType}`
      )
      .then((response) => {
        setCategory(response.data.body.result.categories);
        if (slug != "all") {
          const cat =
            response &&
            response.data.body.result.categories.find(
              (category) => category.slug === slug
            );
          setSelectedCategory(cat.category);
          setSearchCategory({
            category_name: cat.category,
            category_slug: cat.slug,
          });
        }

        if (subcat != "all") {
          const subCat =
            response &&
            response.data.body.result.subcategories.find(
              (category) => category.slug === subcat
            );
          setSelectedSubCategory(subCat.category);
          setSearchSubCategory({
            subcategory_name: subCat.category,
            subcategory_slug: subCat.slug,
          });
        } else {
          setSelectedSubCategory("");
        }

        if (countyUrl != "all") {
          const countySelected =
            response &&
            response.data.body.result.counties.find(
              (category) => category.slug === countyUrl
            );
          setSelectedCounty(countySelected.county);
          setSearchCounty({
            county_name: countySelected.county,
            county_slug: countySelected.slug,
          });
        } else {
          setSelectedCounty("");
        }

        if (adType != "all") {
          setSelectedAccount(adType.replace(/and/g, "&").replace(/-/g, " "));
          setSearchAccount(adType.replace(/and/g, "&").replace(/-/g, " "));
        } else {
          setSelectedAccount("");
        }

        if (slug != "all" && subcat == "all") {
          if (countyUrl != "all") {
            setTitle(`${selectedCategory} in ${selectedCounty}`);
          } else {
            setTitle(`${selectedCategory}`);
          }
        } else if (subcat != "all") {
          if (countyUrl != "all") {
            setTitle(`${selectedSubCategory} in ${selectedCounty}`);
          } else {
            setTitle(`${selectedSubCategory}`);
          }
        } else {
          if (countyUrl != "all") {
            setTitle(`in ${selectedCounty}`);
          } else {
            setTitle("");
          }
        }
        setSubcategory(response.data.body.result.subcategories);
       
        setAccount(response.data.body.result.account);
        setCounty(response.data.body.result.counties);
      })
      .catch((err) => {});
  }, [
    slug,
    subcat,
    countyUrl,
    adType,
    selectedCategory,
    selectedSubCategory,
    selectedAccount,
    selectedCounty,
  ]);

  useEffect(() => {
    setProduct([]);
    setLoad(true);
    axios
      .get(
        `${DealerApi}/listing?per_page=${perPage}&category_slug=${
          slug == "newest_listing" ? "" : slug == "all" ? "" : slug
        }&subcategory_slug=${subcat == "all" ? "" : subcat}&ad_type=${
          adType == "all" ? "" : adType
        }&county_slug=${
          countyUrl == "all" ? "" : countyUrl
        }&page_no=${currentPage}&sorted_by=${sortingOption}`
      )
      .then((response) => {
        setLoad(false);
        if (response.data.body.result.data.length === 0) {
          setNotFound(true);
          setTotalAds(0);
          setError("No Product Found.");
        } else {
          setError(null);
          setNotFound(false);
          setTotalAds(response.data.body.result.total);
          if (slug == "all" && selectedCounty == "" && selectedAccount == "") {
            setSaveSearch(false);
          } else {
            setSaveSearch(true);
          }
        }
        setProduct(response.data.body.result.data);
        setBanner(response.data.body.result.banner);
        const calculatedTotalPages = Math.ceil(
          response.data.body.result.total / perPage
        );
        setTotalPages(calculatedTotalPages);
      })
      .catch((err) => {
        setLoad(false);
        if (err.response.data.body.success == 0) {
          setNotFound(true);
          setError("No Product Found.");
          setTotalAds(0);
        }
      });
  }, [
    slug,
    subcat,
    countyUrl,
    adType,
    selectedCategory,
    selectedSubCategory,
    currentPage,
    perPage,
    selectedAccount,
    selectedCounty,
    sortingOption,
  ]);

  const handleArrow = (cat) => {
    setSubcategory("");
    if(cat.slug == subId && subcategory.length != 0) {
      setSubcategory("");
    } else {
      setSubId(cat.slug)
      setSelectedCategoryId(cat.slug);
      setCategorySlug(cat.slug);
      const api = axios
        .get(`${DealerApi}/filter/all?category_slug=${cat.slug}`)
        .then((response) => {
          setSubcategory(response.data.body.result.subcategories);
        })
        .catch((err) => {});
    }
  };

  const handleCategory = (cat) => {
    setCategorySlug(cat.slug);
    setSubCategorySlug("all");
    setCurrentPage(1);
    navigate(
      `/search?cat=${cat.slug}&sub_cat=all&county=${countySlug}&ad_type=${accountSlug}`
    );
    setSelectedCategoryId(cat.slug);
    setSelectedCategory(cat.category);
    setSearchCategory({ category_name: cat.category, category_slug: cat.slug });
    setSortingOption("desc_date");
  };

  const handleSubCategory = (sub) => {
    setSubCategorySlug(sub.slug);
    setSelectedSubCategory(sub.category);
    setCurrentPage(1);
    if (sub.slug == "cars" || sub.slug == "4x4-wheel-drive") {
      navigate(
        `/car_search?cat=${categorySlug}&sub_cat=${sub.slug}&county=${countySlug}&ad_type=${accountSlug}`
      );
    } else {
      navigate(
        `/search?cat=${categorySlug}&sub_cat=${sub.slug}&county=${countySlug}&ad_type=${accountSlug}`
      );
    }
    setSearchSubCategory({
      subcategory_name: sub.category,
      subcategory_slug: sub.slug,
    });
    setSortingOption("desc_date");
  };

  const handleAccountClick = (acc) => {
    setAccountSlug(acc);
    setCurrentPage(1);
    navigate(
      `/search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${acc}`
    );
    setSelectedAccount(acc);
    setSearchAccount(acc);
    setSortingOption("desc_date");
  };

  const handleCountyClick = (county) => {
    setCountySlug(county.slug);
    setCurrentPage(1);
    navigate(
      `/search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${county.slug}&ad_type=${accountSlug}`
    );
    setSelectedCounty(county.county);
    setSearchCounty({ county_name: county.county, county_slug: county.slug });
    setSortingOption("desc_date");
  };

  const handleBannerClick = (link) => {
    window.open(link, "_blank");
  };

  const handleSortingChange = (label) => {
    setCurrentPage(1);
    switch (label) {
      case "relevance":
        setSortingOption("desc_score");
        break;
      case "mostRecent":
        setSortingOption("desc_date");
        break;
      case "lowestPrice":
        setSortingOption("asc_price");
        break;
      case "highestPrice":
        setSortingOption("desc_price");
        break;
      default:
        break;
    }
  };

  const handleBreadcrumbClick = async (category, subcategory) => {
    setPerPage(16);
    setSortingOption("desc_date");
    if (subcategory) {
      setSelectedCounty("");
      setSearchCounty("");
      setCurrentPage(1);
      navigate(`/${slug}/${subcat}`);
    } else {
      setSelectedAccount("");
      setSelectedCounty("");
      setSearchCounty("");
      setSearchSubCategory("");
      setSelectedSubCategory("");
      setTitle(category.category);
      setCurrentPage(1);
      navigate(`/${slug}`);
    }
  };

  const handleRemove = (type) => {
    setCurrentPage(1);
    setPerPage(16);
    setSortingOption("desc_date");
    switch (type) {
      case "subcategory":
        setSelectedCounty("");
        setSelectedAccount("");
        setSelectedSubCategory("");
        setSearchSubCategory("");
        setCurrentPage(1);
        navigate(`/${slug}`);
        break;
      case "account":
        setSelectedAccount("");
        setSearchAccount("");
        setAccountSlug("all");
        setCurrentPage(1);
        navigate(
          `/search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=all`
        );
        break;
      case "county":
        setSelectedCounty("");
        setSearchCounty("");
        setCountySlug("all");
        setCurrentPage(1);
        navigate(
          `/search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=all&ad_type=${accountSlug}`
        );
        break;
      default:
        break;
    }
  };

  const wishListFunction = async () => {
    if (tokenObject) {
      await axios
        .get(`${DealerApi}/wishlist/${tokenObject.id}`)
        .then((res) => {
          setWishlist(res.data.body.result.data);
        })
        .catch((err) => {});
    }
  };

  const updateWishlist = async () => {
    await wishListFunction();

    const updatedProducts = product.map((product) => {
      product.isInWishlist = isInWishlist(product.id);
      return product;
    });
    setProduct(updatedProducts);
  };

  const isInWishlist = (itemId) => {
    return wishlist && wishlist.some((item) => item.id === itemId);
  };

  useEffect(() => {
    updateWishlist();
  }, []);

  const handleSaveSearch = () => {
    if (!tokenObject || !tokenObject.token || !tokenObject.id) {
      navigate("/login");
    } else {
      axios
        .post(
          `${DealerApi}/search/store`,
          {
            search_key_word: "",
            user_id: tokenObject && tokenObject.id,
            filters: {
              category: searchCategory && searchCategory.category_name,
              category_slug: searchCategory && searchCategory.category_slug,
              subcategory:
                searchSubCategory && searchSubCategory.subcategory_name,
              subcategory_slug:
                searchSubCategory && searchSubCategory.subcategory_slug,
              county: searchCounty && searchCounty.county_name,
              county_slug: searchCounty && searchCounty.county_slug,
              ad_type: searchAccount,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${tokenObject && tokenObject.token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setToastMessage("Successfully save in search page");
          setTimeout(() => {
            setToastMessage("");
          }, 5000);
        })
        .catch((e) => {});
    }
  };

  const handleAll = (all) => {
    if (all == "catAll") {
      setCategorySlug("all");
      setSubCategorySlug("all");
      setSubcategory("");
      setSelectedCategory("");
      setSelectedSubCategory("");
      setSelectedCategoryId("");
      navigate(
        `/search?cat=all&sub_cat=all&county=${countySlug}&ad_type=${accountSlug}`
      );
    } else if (all == "accAll") {
      setAccountSlug("all");
      setSelectedAccount("");
      navigate(
        `/search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=all`
      );
    } else {
      setCountySlug("all");
      setSelectedCounty("");
      navigate(
        `/search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=all&ad_type=${accountSlug}`
      );
    }
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={`${
            subcat == "all" ? selectedCategory : selectedSubCategory
          } - Buy or sell anything online in Ireland, Northern, cars, furniture,household, agricultural, electrical, music, holidays, wedding, fashion, farming`}
        />
        <meta
          name="keywords"
          content={`${
            subcat == "all" ? selectedCategory : selectedSubCategory
          } - thedealer.ie your one stop shop for buying or selling goods online in Ireland, Northern Ireland`}
        />
      </Helmet>
      <div className="category_page_main">
        <div className="container">
          <div className="row">
            <Breadcrumb>
              <Breadcrumb.Item href="/" className="home_breadcrumb">
                Home
              </Breadcrumb.Item>

              {selectedCategory && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick({ slug: category, category: title })
                  }
                >
                  {selectedCategory}
                </Breadcrumb.Item>
              )}

              {selectedSubCategory && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      { slug: subcategory, category: selectedSubCategory }
                    )
                  }
                >
                  {selectedSubCategory}
                </Breadcrumb.Item>
              )}

              {selectedCounty && (
                <Breadcrumb.Item className="active">
                  {selectedCounty}
                </Breadcrumb.Item>
              )}
            </Breadcrumb>

            <div className="col-lg-3 col-md-4 d-lg-block aside">
              {selectedSubCategory || selectedAccount || selectedCounty ? (
                <div className="selected_item_div">
                  <div className="selected_item_div">
                    <span className="selected_heading">Your Selection:</span>
                  </div>

                  {selectedSubCategory && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Subcategory:</span>{" "}
                        <span className="selected_div">
                          {selectedSubCategory}
                        </span>
                      </p>
                      <button
                        onClick={() => handleRemove("subcategory")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedAccount && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Account:</span>{" "}
                        <span className="selected_div">{selectedAccount}</span>
                      </p>
                      <button
                        onClick={() => handleRemove("account")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedCounty && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">County:</span>{" "}
                        <span className="selected_div">{selectedCounty}</span>
                      </p>
                      <button
                        onClick={() => handleRemove("county")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}
                </div>
              ) : null}

              <div
                className={`product_sidebar pt-20 ${
                  showOffcanvas ? "d-none d-md-block d-sm-block" : "" // Hide for smaller screens when Offcanvas is open
                }`}
              >
                <Accordion defaultActiveKey="0">
                  {showCategoryAccordions &&
                    category &&
                    category.length > 0 && (
                      <Accordion.Item eventKey="0">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Categories</h5>
                            </Accordion.Header>
                          </div>

                          <Accordion.Body className="accordion_body">
                            <div className="categories-container">
                              <p
                                className="category"
                                onClick={() => {
                                  handleAll("catAll");
                                }}
                              >
                                Categories (All)
                              </p>
                              {category.map((categories) => (
                                <div key={categories.id} className="category">
                                  <p onClick={() => handleCategory(categories)}>
                                    <IoIosArrowForward
                                      onClick={(e) => {
                                        e.stopPropagation(); 
                                        handleArrow(categories);
                                      }}
                                    />{" "}
                                    {categories.category}
                                  </p>
                                  {selectedCategoryId === categories.slug &&
                                    subcategory && (
                                      <ul className="subcategories">
                                        {subcategory.map((subcategories) => (
                                          <li
                                            key={subcategories.id}
                                            onClick={() =>
                                              handleSubCategory(subcategories)
                                            }
                                          >
                                            {subcategories.category} (
                                            {subcategories.listing_count})
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                </div>
                              ))}
                            </div>
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>
                    )}

                  {account && account.length > 0 && (
                    <Accordion.Item eventKey="1" className="accordion_space">
                      <div className="sidebar_categories mt-10">
                        <div className="sidebar_title">
                          <Accordion.Header>
                            <h5 className="category_title">Account</h5>
                          </Accordion.Header>
                        </div>
                        <Accordion.Body className="accordion_body">
                          <p
                            className="category"
                            onClick={() => {
                              handleAll("accAll");
                            }}
                          >
                            Account (All)
                          </p>

                          {account &&
                            account.length > 0 &&
                            account.map((account) => (
                              <div key={account.id} className="category">
                                <p
                                  onClick={() =>
                                    handleAccountClick(account.ad_type)
                                  }
                                  className="account_title"
                                >
                                  {account.ad_type} ({account.listing_count})
                                </p>
                              </div>
                            ))}
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                  )}

                  {county && county.length > 0 && (
                    <Accordion.Item eventKey="2" className="accordion_space">
                      <div className="sidebar_categories mt-10">
                        <div className="sidebar_title">
                          <Accordion.Header>
                            <h5 className="category_title">County</h5>
                          </Accordion.Header>
                        </div>
                        <Accordion.Body className="accordion_body">
                          <p
                            className="category"
                            onClick={() => {
                              handleAll("countyAll");
                            }}
                          >
                            Counties (All)
                          </p>
                          {county &&
                            county.length > 0 &&
                            county.map((county) => (
                              <p
                                key={county.id}
                                className="category"
                                onClick={() => handleCountyClick(county)}
                              >
                                {county.county} ({county.listing_count})
                              </p>
                            ))}
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                  )}
                </Accordion>
              </div>
            </div>

            <div className="col-lg-9 col-md-12">
              {banner && banner.length > 0 && (
                <div className="carousel_card1 text-center">
                  <div className="carousel-container">
                    <Carousel
                      controls={false}
                      indicators={false}
                      interval={5000}
                    >
                      {banner.map((item, index) => (
                        <Carousel.Item
                          key={index}
                          onClick={() => handleBannerClick(item.banner_link)}
                        >
                          <img
                            className="feature_img"
                            src={item.url}
                            alt={item.banner_name}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                </div>
              )}

              <Toast
                show={toastMessage !== ""}
                onClose={() => setToastMessage("")}
                className="fade bg-success toast_message_div"
              >
                <Toast.Body className="toast_message">
                  {toastMessage}
                </Toast.Body>
              </Toast>

              <div className="ads_total_div">
                <h3>{title}</h3>
                <h3>{totalAds} Ads</h3>
              </div>

              <Offcanvas
                show={showOffcanvas}
                onHide={handleCloseOffcanvas}
                className="d-block d-lg-none"
                style={{ overflowY: "scroll" }}
                scroll={true}
                backdrop={false}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                  {selectedSubCategory || selectedAccount || selectedCounty ? (
                    <div className="selected_item_div">
                      <div className="selected_item_div">
                        <span className="selected_heading">
                          Your Selection:
                        </span>
                      </div>

                      {selectedSubCategory && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Subcategory:</span>{" "}
                            <span className="selected_div">
                              {selectedSubCategory}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("subcategory")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedAccount && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Account:</span>{" "}
                            <span className="selected_div">
                              {selectedAccount}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("account")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedCounty && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">County:</span>{" "}
                            <span className="selected_div">
                              {selectedCounty}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("county")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}
                    </div>
                  ) : null}

                  <Accordion defaultActiveKey="0">
                    {showCategoryAccordions &&
                      category &&
                      category.length > 0 && (
                        <Accordion.Item eventKey="0">
                          <div className="sidebar_categories mt-10">
                            <div className="sidebar_title">
                              <Accordion.Header>
                                <h5 className="category_title">Categories</h5>
                              </Accordion.Header>
                            </div>

                            <Accordion.Body className="accordion_body">
                              <div className="categories-container">
                                <p
                                  className="category"
                                  onClick={() => {
                                    handleAll("catAll");
                                  }}
                                >
                                  Categories (All)
                                </p>
                                {category.map((categories) => (
                                  <div key={categories.id} className="category">
                                    <p
                                      onClick={() => handleCategory(categories)}
                                    >
                                      <IoIosArrowForward
                                        onClick={(e) => {
                                          e.stopPropagation(); 
                                          handleArrow(categories);
                                        }}
                                      />{" "}
                                      {categories.category}
                                    </p>
                                    {selectedCategoryId === categories.slug &&
                                      subcategory && (
                                        <ul className="subcategories">
                                          {subcategory.map((subcategories) => (
                                            <li
                                              key={subcategories.id}
                                              onClick={() =>
                                                handleSubCategory(subcategories)
                                              }
                                            >
                                              {subcategories.category} (
                                              {subcategories.listing_count})
                                            </li>
                                          ))}
                                        </ul>
                                      )}
                                  </div>
                                ))}
                              </div>
                            </Accordion.Body>
                          </div>
                        </Accordion.Item>
                      )}

                    <Accordion.Item eventKey="1" className="accordion_space">
                      <div className="sidebar_categories mt-10">
                        <div className="sidebar_title">
                          <Accordion.Header>
                            <h5 className="category_title">Account</h5>
                          </Accordion.Header>
                        </div>
                        <Accordion.Body className="accordion_body">
                          <p
                            className="category"
                            onClick={() => {
                              handleAll("accAll");
                            }}
                          >
                            Account (All)
                          </p>

                          {account &&
                            account.length > 0 &&
                            account.map((account) => (
                              <div key={account.id} className="category">
                                <p
                                  onClick={() =>
                                    handleAccountClick(account.ad_type)
                                  }
                                  className="account_title"
                                >
                                  {account.ad_type} ({account.listing_count})
                                </p>
                              </div>
                            ))}
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2" className="accordion_space">
                      <div className="sidebar_categories mt-10">
                        <div className="sidebar_title">
                          <Accordion.Header>
                            <h5 className="category_title">County</h5>
                          </Accordion.Header>
                        </div>
                        <Accordion.Body className="accordion_body">
                          <p
                            className="category"
                            onClick={() => {
                              handleAll("countyAll");
                            }}
                          >
                            Counties (All)
                          </p>
                          {county &&
                            county.length > 0 &&
                            county.map((county) => (
                              <p
                                key={county.id}
                                className="category"
                                onClick={() => handleCountyClick(county)}
                              >
                                {county.county} ({county.listing_count})
                              </p>
                            ))}
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                  </Accordion>

                  <button
                    onClick={() => {
                      handleCloseOffcanvas();
                    }}
                    className="login_btn mt-4"
                  >
                    Apply Filter
                  </button>
                </Offcanvas.Body>
              </Offcanvas>

              <div className="product_dropdown_main">
                <div className="product_dropdown">
                  {/* Offcanvas Trigger Button */}
                  <button
                    onClick={handleShowOffcanvas}
                    className="d-block d-lg-none filter_btn"
                  >
                    Filter
                  </button>

                  <Dropdown className="dropdown_page">
                    <Dropdown.Toggle
                      className="dropdown_page_main"
                      id="dropdown-basic"
                    >
                      Show {perPage}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleShowPerPage(16)}>
                        Show 16
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleShowPerPage(32)}>
                        Show 32
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleShowPerPage(60)}>
                        Show 60
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown className="dropdown_page">
                    <Dropdown.Toggle
                      className="dropdown_page_main"
                      id="dropdown-basic"
                    >
                      {sortingOption === "desc_score"
                        ? "Relavance"
                        : sortingOption === "desc_date"
                        ? "Most Recent"
                        : sortingOption === "asc_price"
                        ? "Lowest Price"
                        : sortingOption === "desc_price"
                        ? "Highest Price"
                        : ""}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handleSortingChange("relevance")}
                      >
                        Relavance
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => handleSortingChange("mostRecent")}
                      >
                        Most Recent
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => handleSortingChange("lowestPrice")}
                      >
                        Lowest Price
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => handleSortingChange("highestPrice")}
                      >
                        Highest Price
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {saveSearch && (
                    <button
                      onClick={handleSaveSearch}
                      className="save_search_btn"
                    >
                      Save Search Results
                    </button>
                  )}
                </div>

                <div className="product_dropdown product_dropdown_grid">
                  <LuGrid
                    className={`grid_icon ${isGrid ? "active" : ""}`}
                    onClick={toggleGrid}
                  />

                  <LiaThListSolid
                    className={`list_icon ${!isGrid ? "active" : ""}`}
                    onClick={toggleList}
                  />
                </div>
              </div>

              {notFound && (
                <div class="preview clicks">
                  <div class="image">
                    <img src={megaphone} alt="megaphone" />
                  </div>
                  <h1>Sorry, no results found.</h1>
                  <p>
                    We have nothing that matches your search. Browse all of our
                    ads using the{" "}
                    <a
                      href={process.env.REACT_APP_API_REPORT}
                      className="main_category"
                    >
                      main categories
                    </a>
                    .
                  </p>
                </div>
              )}

              <div className="load_img_div">
                {load && !error && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    className="loader_img"
                  />
                )}
              </div>

              {!error && (
                <div>
                  <div className={`${grid ? "grid" : "list"}`}>
                    {grid &&
                      product.map((product) => {
                        return (
                          <>
                            <CategoryProductCard
                              key={product.id}
                              product={product}
                              wishlist={wishlist}
                              isInWishlist={isInWishlist(product.id)}
                              updateWishlist={updateWishlist}
                            />
                          </>
                        );
                      })}
                    {isGrid &&
                      product.map((product) => {
                        return (
                          <>
                            <CategoryListing
                              key={product.id}
                              product={product}
                              wishlist={wishlist}
                              isInWishlist={isInWishlist(product.id)}
                              updateWishlist={updateWishlist}
                            />
                          </>
                        );
                      })}
                  </div>

                  {load == false && product && totalPages && totalPages > 1 && (
                    <div className="pagination">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default CategorySearch;
