import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import ad from "../Images/ad.png";
import myad from "../Images/myad.png";
import edit from "../Images/edit.png";
import wishlist from "../Images/wishlist.png";
import credit from "../Images/credit.png";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import history from "../Images/history.png";
import axios from "axios";
import { DealerApi } from "../Component/Api";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Heder from "../Component/Heder";

const Dashboard = ({setting, balance}) => {
  const navigate = useNavigate();
  const [checkBalance, setCheckBalance] = useState("");
  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);

  useEffect(() => {
    if (tokenObject) {
      axios
        .get(`${DealerApi}/user/account/check/${tokenObject.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenObject.token}`,
          },
        })
        .then((res) => {
          setCheckBalance(res.data.body.result);
        })
        .catch((err) => {});
    }
  }, []);

  const handleMouseAd = (event) => {
    if (event.button === 1) {
      event.preventDefault();
      Cookies.remove("listingToken");
      if (tokenObject && tokenObject.user_type == "admin") {
        window.open(
          `${process.env.REACT_APP_ADMIN_APP_API_URL}/advert/index/private`,
          "_blank"
        );
      } else if (tokenObject) {
        if (tokenObject) {
          if (balance && balance.result === true) {
            window.open("/online_ad");
          } else {
            window.open("/payments");
          }
        }
      }
    }
  };

  const handleAd = (event) => {
    event.preventDefault();
    Cookies.remove("listingToken");
    if (tokenObject && tokenObject.user_type == "admin") {
      window.open(
        `${process.env.REACT_APP_ADMIN_APP_API_URL}/advert/index/private`,
        "_self"
      );
    } else {
      if (balance && balance.result === true) {
        navigate("/online_ad");
      } else {
        navigate("/payments");
      }
    }
  };

  const handleMouseMyAd = (event) => {
    event.preventDefault();
    if (event.button === 1) {
      if (tokenObject.user_type == "admin") {
        window.open(
          `${process.env.REACT_APP_ADMIN_APP_API_URL}/dashboard`,
          "_blank"
        );
      } else {
        window.open("/my_online_ads");
      }
    }
  };

  const handleMyad = (event) => {
    event.preventDefault();
    if (tokenObject.user_type == "admin") {
      window.open(
        `${process.env.REACT_APP_ADMIN_APP_API_URL}/dashboard`,
        "_self"
      );
    } else {
      navigate("/my_online_ads");
    }
  };

  const handleMouseUp = (event) => {
    event.preventDefault();
    if (event.button === 1) {
      if (tokenObject.user_type == "admin") {
        window.open(
          `${process.env.REACT_APP_ADMIN_APP_API_URL}/dashboard`,
          "_blank"
        );
      } else {
        window.open("/watchlist");
      }
    }
  };

  const handleWishlist = (event) => {
    event.preventDefault();
    if (tokenObject.user_type == "admin") {
      window.open(
        `${process.env.REACT_APP_ADMIN_APP_API_URL}/dashboard`,
        "_self"
      );
    } else {
      navigate("/watchlist");
    }
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    if (event.button === 1) {
      if (tokenObject.user_type == "admin") {
        window.open(
          `${process.env.REACT_APP_ADMIN_APP_API_URL}/dashboard`,
          "_blank"
        );
      } else {
        window.open("/payments");
      }
    }
  };

  const handleCredit = (event) => {
    event.preventDefault();
    if (tokenObject.user_type == "admin") {
      window.open(
        `${process.env.REACT_APP_ADMIN_APP_API_URL}/dashboard`,
        "_self"
      );
    } else {
      navigate("/payments");
    }
  };

  return (
    <>
    <Heder name="client" setting={setting}/>
      <div className="advert_main_div">
        <p className="advert_heading">Dashboard</p>
      </div>

      <div className="container">
        <div className="dashboard_main">
          <p className="dashboard_heading">What would you like to do today?</p>
          <div className="dashboard">
            <Nav.Link as={Link} onClick={handleAd} onMouseDown={handleMouseAd}>
              {tokenObject.user_type != "admin" && (
                <div className="dashboard_box">
                  <img src={ad} alt="place an ad" className="dashboard_icon" />
                  <div className="dashboard_rectangle">
                    <h4 className="dashboard_btn_label">Place an Ad</h4>
                  </div>
                </div>
              )}
            </Nav.Link>
            <Nav.Link
              as={Link}
              onClick={handleMyad}
              onMouseDown={handleMouseMyAd}
            >
              <div className="dashboard_box">
                <img src={myad} alt="myad" className="dashboard_icon" />
                <div className="dashboard_rectangle">
                  <h4 className="dashboard_btn_label">My Ads</h4>
                </div>
              </div>
            </Nav.Link>
            <Nav.Link as={Link} to="/edit_account">
              {tokenObject.user_type != "admin" && (
                <div className="dashboard_box">
                  <img
                    src={edit}
                    alt="edit account"
                    className="dashboard_icon"
                  />
                  <div className="dashboard_rectangle">
                    <h4 className="dashboard_btn_label">Edit Account</h4>
                  </div>
                </div>
              )}
            </Nav.Link>
            <Nav.Link
              as={Link}
              onClick={handleWishlist}
              onMouseDown={handleMouseUp}
            >
              <div className="dashboard_box">
                <img
                  src={wishlist}
                  alt="watchlist"
                  className="dashboard_icon"
                />
                <div className="dashboard_rectangle">
                  <h4 className="dashboard_btn_label">Watchlist</h4>
                </div>
              </div>
            </Nav.Link>
            <Nav.Link
              as={Link}
              onClick={handleCredit}
              onMouseDown={handleMouseDown}
            >
              <div className="dashboard_box">
                <img src={credit} alt="credit" className="dashboard_icon" />
                <div className="dashboard_rectangle">
                  <h4 className="dashboard_btn_label">Credit</h4>
                </div>
              </div>
            </Nav.Link>

            <Nav.Link as={Link} to="/saved_search">
              {tokenObject.user_type != "admin" && (
                <div className="dashboard_box">
                  <img
                    src={history}
                    alt="saved search"
                    className="dashboard_icon"
                  />
                  <div className="dashboard_rectangle">
                    <h4 className="dashboard_btn_label">Saved Search</h4>
                  </div>
                </div>
              )}
            </Nav.Link>
          </div>
          {tokenObject.user_type == "business" && (
            <>
              <div className="balance_check_div">
                <h1 className="balance_heading">
                  Your account balance is € {checkBalance}
                </h1>
                <p className="payment_text">
                  Classified Ads are free for private users. Business users
                  require a minimum of €3.00 to place an advert.
                </p>
                <div className="payment_card">
                  <Nav.Link
                    as={Link}
                    onClick={handleCredit}
                    onMouseDown={handleMouseDown}
                  >
                    <button className="login_btn">Buy Credit</button>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    onClick={handleAd}
                    onMouseDown={handleMouseAd}
                  >
                    <button className="login_btn">Place Ad</button>
                  </Nav.Link>
                </div>
              </div>
              <div className="join_business_dir">
                <h1 className="balance_heading">Join our Business Directory</h1>
                <p className="payment_text">
                  Business directory members enjoy{" "}
                  <strong>unlimited free adverts</strong> on TheDealer.ie, as
                  well as lots of other great perks!
                </p>
                <div className="payment_card">
                  <Nav.Link as={Link} to="/business_directory/info">
                    <button className="login_btn">Tell Me More</button>
                  </Nav.Link>
                  <Nav.Link as={Link} to="/contact">
                    <button className="login_btn">Get In Touch</button>
                  </Nav.Link>
                </div>
              </div>
            </>
          )}
          {tokenObject.user_type == "private" && (
            <>
              <div className="place_ad_balance_div">
                <h1 className="balance_heading">Place a Free Advert</h1>
                <p className="payment_text">
                  Four months online, ten large photos and listing in local
                  printed edition.
                </p>
                <div className="payment_card">
                  {/* <Nav.Link as={Link} onClick={handleCredit} onMouseDown={handleMouseDown}><button className="login_btn">
              Buy Credit
            </button></Nav.Link> */}
                  <Nav.Link
                    as={Link}
                    onClick={handleAd}
                    onMouseDown={handleMouseAd}
                  >
                    <button className="login_btn">Start selling</button>
                  </Nav.Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

    </>
  );
};

export default Dashboard;
