import React, { useEffect, useMemo, useRef, useState } from "react";
import "./BusinessDirectoryDetail.css";
import { Breadcrumb } from "react-bootstrap";
import facebook from "../Images/business_fb.png";
import pintrest from "../Images/business_pintrest.png";
import twitter from "../Images/twittericon.png";
import email from "../Images/mail.png";
import {
  DirectionsRenderer,
  GoogleMap,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { IoSearch } from "react-icons/io5";
import { useParams } from "react-router";
import axios from "axios";
import { DealerApi } from "../Component/Api";
import ProductCard from "../Component/ProductCard";
import Cookies from "js-cookie";
import marker from "../Images/icon-business-hover.png";
import { Helmet } from "react-helmet";

const BusinessDirectoryDetail = () => {
  const [showModal, setShowModal] = useState(true);
  const [data, setData] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);
  const [searchLocation, setSearchLocation] = useState("");
  const [directions, setDirections] = useState(null);
  const [clientId, setClientId] = useState([]);
  const [showDetails, setShowDetails] = useState("");
  const [showToggleDetails, setShowToggleDetails] = useState(false);
  const [showToggleMap, setShowToggleMap] = useState(false);
  const [showMap, setShowMap] = useState("");
  const { id } = useParams();
  const [mapCenter, setMapCenter] = useState(""); 
  const [isMobile, setIsMobile] = useState(false);

  // Detect if the user is on a mobile device
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIsMobile(true);
    }
  }, []);

  // document.title = `${data.company_name}${data.category ? `, ${data.category}` : ''}${data.town ? `, ${data.town}` : ''}${data.county ? `, ${data.county}` : ''}`;

  const generateTitle = () => {
    return `${data.company_name}${data.category ? `, ${data.category}` : ""}${
      data.town ? `, ${data.town}` : ""
    }${data.county ? `, ${data.county}` : ""}`;
  };

  useEffect(() => {
    axios
      .get(`${DealerApi}/user/business/directory/${id}`)
      .then((res) => {
        setData(res.data.body.result);
        setMapCenter({
          lat: +res.data.body.result.user_lat,
          lng: +res.data.body.result.user_long,
        });
      })
      .catch((err) => {});
  }, [id]);

  useEffect(() => {
    axios
      .get(`${DealerApi}/keys`)
      .then((response) => {
        setClientId(response.data.body.result.google_api_key);
      })
      .catch((err) => {});
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const wishListFunction = async () => {
    if (tokenObject) {
      await axios
        .get(`${DealerApi}/wishlist/${tokenObject.id}`)
        .then((res) => {
          setWishlist(res.data.body.result.data);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    wishListFunction();
  }, []);

  const isInWishlist = (itemId) => {
    return wishlist && wishlist.some((item) => item.id === itemId);
  };

  const updateWishlist = () => {
    wishListFunction();
  };

  const handleSearchLocationEnter = (event) => {
    if (event.key === "Enter") {
      getDirections();
    }
  };

  // Handle search location button click
  const handleSearchLocation = () => {
    getDirections();
  };

  // Function to get directions from searchLocation to the user's destination
  const getDirections = () => {
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: searchLocation,
        destination: { lat: +data.user_lat, lng: +data.user_long },
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === "OK") {
          setDirections(response);
        } else {
          console.error("Directions request failed due to " + status);
        }
      }
    );
  };

  // Open the Google Maps app or web on mobile
  const openInGoogleMapsApp = () => {
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(searchLocation)}&destination=${+data.user_lat},${+data.user_long}&travelmode=driving`;
    window.open(googleMapsUrl, "_blank");
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const convertToUrl = (text) => {
    text = text.trim().toLowerCase();
    text = text.replace(/\s+/g, "-");
    text = text.replace(/(\d+)\.(\d+)/g, "$1-$2");
    text = text.replace(/[^a-z0-9-]/g, "");
    return text;
  };

  let urlText = "";
  if (data && data.company_name) {
    urlText = convertToUrl(data && data.company_name);
  }
  const productUrl = `${process.env.REACT_APP_API_BUSINESS_SOCIAL}/${id}/${urlText}`;
  const tweetText = `${data.company_name}${
    data.category ? `, ${data.category}` : ""
  }${data.address_one ? `, ${data.address_one}` : ""}${
    data.county ? `, ${data.county}` : ""
  }${data.telephone ? `, Tel: ${data.telephone}` : ""}${
    data.email ? `, Email: ${data.email}` : ""
  }`;

  const handleFacebook = () => {
    const urlText = convertToUrl(data && data.company_name);
    if (data) {
      const productUrl = `${process.env.REACT_APP_API_BUSINESS_SOCIAL}/${id}/${urlText}`;
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${productUrl}`;
      window.open(facebookShareUrl, "_blank", "width=600,height=400");
    }
  };

  const handlePintrest = () => {
    const description = encodeURIComponent(data && data.description);
    const imageUrl = encodeURIComponent(data.picture && data.picture.url.small);
    const url = encodeURIComponent(
      `${process.env.REACT_APP_API_BUSINESS_SOCIAL}/${id}/${urlText}`
    );

    const pinterestShareUrl = `https://in.pinterest.com/pin-builder/?description=${description}&media=${imageUrl}&url=${url}`;

    window.open(pinterestShareUrl, "_blank", "width=600,height=400");
  };

  const handletwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      productUrl
    )}&text=${encodeURIComponent(tweetText)}`;

    window.open(twitterShareUrl, "_blank");
  };

  const handleMail = () => {
    if (data) {
      const subject = encodeURIComponent(
        `${data.company_name}${data.category ? `, ${data.category}` : ""}, ${
          data.town
        }, ${data.county}`
      );
      const body = encodeURIComponent(
        `${data.company_name}${data.category ? `, ${data.category}` : ""}${
          data.town ? `, ${data.town}` : ""
        }${data.county ? `, ${data.county}` : ""}${
          data.email ? `, Email: ${data.email}` : ""
        }`
      );
      const url = `mailto:?subject=${subject}&body=${body}`;
      window.open(url, "_blank", "width=600,height=400");
    }
  };

  const defaultCenter = {
    lat: +data.user_lat,
    lng: +data.user_long,
  };

  const renderDescription = (description) => {
    return description.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const handleDetails = () => {
    setShowToggleMap(false);
    setShowToggleDetails(!showToggleDetails);
    setShowMap("");
    setShowDetails("blockDetails");
  };

  const handleMap = () => {
    setShowToggleDetails(false);
    setShowToggleMap(!showToggleMap);
    setShowDetails("");
    setShowMap("blockMap");
  };

  return (
    <>
      <Helmet>
        <title>{data.meta_title || generateTitle()}</title>
        <meta name="description" content={data.meta_description} />
        <meta name="keywords" content={data.meta_keywords} />
        {/* <title>{`${data.company_name}${data.category ? `, ${data.category}` : ''}${data.town ? `, ${data.town}` : ''}${data.county ? `, ${data.county}` : ''}`}</title>
        <meta
          name="description"
          content={`${data.company_name}${data.category ? `, ${data.category}` : ''}${data.town ? `, ${data.town}` : ''}${data.county ? `, ${data.county}` : ''}${data.telephone ? `, Tel: ${data.telephone}` : ''}${data.email ? `, Email: ${data.email}` : ''}`}
        />
        <meta
          name="keywords"
          content={`${data.company_name}${data.category ? `, ${data.category}` : ''}${data.town ? `, ${data.town}` : ''}${data.county ? `, ${data.county}` : ''}${data.telephone ? `, Tel: ${data.telephone}` : ''}${data.email ? `, Email: ${data.email}` : ''}`}
        /> */}
        <meta property="og:title" content={data && data.company_name} />
        <meta property="og:description" content={data && data.description} />
        <meta property="og:url" content={productUrl} />
        <meta property="og:image" content={data && data?.picture?.url?.small} />

        <meta name="twitter:card" content={data.image} />
        <meta name="twitter:title" content={data.company_name} />
        <meta name="twitter:description" content={data.description} />
        <meta name="twitter:image" content={data && data?.picture?.url?.small} />

        <meta name="pinterest:description" content={data.description} />
        <meta name="pinterest:image" content={data && data?.picture?.url?.small} />
      </Helmet>

      {showModal && (
        <div className="overlay" id="overlay" onClick={handleCloseModal}></div>
      )}

      {showModal && (
        <div className="modal_business" id="myModal">
          <span className="close-btn" onClick={handleCloseModal}>
            ×
          </span>
          <h1 className="modal_text">
            {" "}
            Welcome to thedealer.ie
            <br />
            Thinking of changing your car?
            <br />
            <a
              className="modal_link"
              href={`${process.env.REACT_APP_API_REPORT}/cars-and-vehicles/cars`}
            >
              Click Here
            </a>
          </h1>
        </div>
      )}

      <div className="container">
        <div className="business_detail_div">
          <Breadcrumb>
            <Breadcrumb.Item onClick={handleGoBack} className="back_breadcrumb">
              Back
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/" className="home_breadcrumb">
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
              href="/business_directory/search/s/all/location"
              className="home_breadcrumb"
            >
              Business Directory
            </Breadcrumb.Item>
            <Breadcrumb.Item className="home_breadcrumb active">
              {data.company_name}
            </Breadcrumb.Item>
          </Breadcrumb>

          <div className="company_detail_div">
            <div className="company_detail">
              <div className="business_company_div">
                <h3>{data.company_name}</h3>
                {data.listings && data.listings.length > 0 && (
                  <div className="ad_btn_div">
                    <button
                      className="login_btn"
                      onClick={() =>
                        window.scrollTo({
                          top:
                            document.getElementById("relatedListings")
                              .offsetTop - 110,
                          behavior: "smooth",
                        })
                      }
                    >
                      See our ads
                    </button>
                  </div>
                )}
              </div>

              {data.picture && data.picture.url.small && (
                <img src={data.picture && data.picture.url.small} alt="title" />
              )}

              {data.contact_name && (
                <div className="detail_span">
                  <div className="business_icon_div">
                    <span className="company_detail_icon">CONTACT</span>
                  </div>
                  <div>
                    <span>{data.contact_name}</span>
                  </div>
                </div>
              )}

              {(data.address_one ||
                data.address_two ||
                data.town ||
                data.county ||
                data.postcode) && (
                <div className="detail_span">
                  <div className="business_icon_div">
                    <span className="company_detail_icon">ADDRESS</span>
                  </div>
                  <div>
                    <span>
                      {data.address_one && `${data.address_one}, `}
                      {data.address_two && `${data.address_two}, `}
                      {data.town && `${data.town}`}
                      {data.county && `, Co. ${data.county}`}
                      {data.postcode && `, ${data.postcode} `}
                    </span>
                  </div>
                </div>
              )}

              {data.telephone &&
                data.telephone != "" &&
                data.telephone != null &&
                data.telephone != 0 && (
                  <div className="detail_span">
                    <div className="business_icon_div">
                      <span className="company_detail_icon">TELEPHONE</span>
                    </div>
                    <div>
                      <span>
                        <a href={`tel:${data.telephone}`} className="mail_info">
                          {data.telephone}
                        </a>
                      </span>
                    </div>
                  </div>
                )}

              {data.mobile &&
                data.mobile != "" &&
                data.mobile != null &&
                data.mobile != 0 && (
                  <div className="detail_span">
                    <div className="business_icon_div">
                      <span className="company_detail_icon">MOBILE</span>
                    </div>
                    <div>
                      <span>
                        <a href={`tel:${data.mobile}`} className="mail_info">
                          {data.mobile}
                        </a>
                      </span>
                    </div>
                  </div>
                )}

              {data.fax &&
                data.fax != "" &&
                data.fax != null &&
                data.fax != 0 && (
                  <div className="detail_span">
                    <div className="business_icon_div">
                      <span className="company_detail_icon">FAX</span>
                    </div>
                    <div>
                      <span>{data.fax}</span>
                    </div>
                  </div>
                )}

              {data.website && (
                <div className="detail_span">
                  <div className="business_icon_div">
                    <span className="company_detail_icon">WEBSITE</span>
                  </div>
                  <div>
                    <span>
                      <a
                        href={`${data.website}`}
                        target="_blank"
                        className="mail_info"
                      >
                        {data.website}
                      </a>
                    </span>
                  </div>
                </div>
              )}

              {data.email && (
                <div className="detail_span">
                  <div className="business_icon_div">
                    <span className="company_detail_icon">EMAIL</span>
                  </div>
                  <div>
                    <span>
                      <a href={`mailto:${data.email}`} className="mail_info">
                        {data.email}
                      </a>
                    </span>
                  </div>
                </div>
              )}

              {data.social_links && data.social_links.length > 0 && (
                <div className="detail_span">
                  <div className="business_icon_div">
                    <span className="company_detail_icon">SOCIAL</span>
                  </div>
                  <div className="social_media_link_business">
                    {data.social_links &&
                      data.social_links.map((icon) => {
                        return (
                          <>
                            <div className="social_icon_business">
                              <span className="mail_info">
                                <a href={icon.link}>
                                  <i
                                    className={icon.social_link_style}
                                    style={{ fontSize: "35px", color: "black" }}
                                  ></i>
                                </a>
                              </span>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              )}

              <div className="detail_span">
                <button
                  className="business_print"
                  onClick={() => window.print()}
                >
                  Print
                </button>
                <p className="business_share">Share - </p>
                <div className="share_social_icon">
                  <img
                    src={facebook}
                    alt="contact"
                    className="share_icon"
                    onClick={handleFacebook}
                  />
                  <img
                    src={pintrest}
                    alt="contact"
                    className="share_pintrest_icon"
                    onClick={handlePintrest}
                  />
                  <img
                    src={twitter}
                    alt="contact"
                    className="share_icon"
                    onClick={handletwitter}
                  />
                  <img
                    src={email}
                    alt="contact"
                    className="share_icon"
                    onClick={handleMail}
                  />
                </div>
              </div>

              <div className="toggle_button">
                <button className="login_btn" onClick={handleDetails}>
                  Details
                </button>
                <button className="login_btn" onClick={handleMap}>
                  Show Map
                </button>
              </div>

              {data.description && (
                <div
                  className={`details_div mt-4 ${
                    showToggleDetails ? showDetails : ""
                  }`}
                >
                  <h3>Details</h3>
                  <div>{renderDescription(data.description)}</div>
                </div>
              )}
            </div>

            {(data.user_lat || data.user_long) && (
              <div className={`company_location ${showToggleMap ? showMap : ""}`}>
                <div className="company_search_div">
                  <input
                    type="text"
                    placeholder="Enter Your Location"
                    className="location_input"
                    value={searchLocation}
                    onChange={(e) => setSearchLocation(e.target.value)}
                    onKeyDown={handleSearchLocationEnter}
                  />
                  <button
                    className="location_button"
                    onClick={handleSearchLocation}
                  >
                    <IoSearch className="location_search_icon" />
                  </button>
                </div>

                <div className="google_location_div">
                {isMobile ? (
          <div>
            {/* If on mobile, provide an option to open in Google Maps */}
            <button onClick={openInGoogleMapsApp}>
              Open in Google Maps App
            </button>
            <p>Or view map below:</p>
          </div>
        ) : null}
                  <LoadScript googleMapsApiKey={clientId.google_api}>
                    <GoogleMap
                      mapContainerStyle={{ height: "65vh", width: "100%" }}
                      zoom={16}
                      center={defaultCenter}
                    >
                      <Marker
                        key={data.id}
                        position={{ lat: +data.user_lat, lng: +data.user_long }}
                        icon={marker}
                      />

                      {directions && (
                        <DirectionsRenderer
                          directions={directions}
                          options={{
                            suppressMarkers: true,
                            panel: document.getElementById("sidebar"),
                          }}
                        />
                      )}
                    </GoogleMap>
                  </LoadScript>
                </div>
                <div className="marking_location_div">
                  <p className="marking_location">
                    Lat: {data.user_lat} Long: {data.user_long}
                  </p>
                </div>

                <div id="sidebar"></div>
              </div>
            )}
          </div>
        </div>
      </div>

      {data.listings && data.listings.length > 0 && (
        <>
          <div className="grid_background" id="relatedListings">
            <h2 className="featured_heading">RELATED LISTINGS</h2>
            <div className="featured_line"></div>
            <div className="product_grid_map container">
              {data &&
                data.listings &&
                data.listings.map((product) => {
                  return (
                    <>
                      <ProductCard
                        key={product.id}
                        product={product}
                        wishlist={wishlist}
                        isInWishlist={isInWishlist(product.id)}
                        updateWishlist={updateWishlist}
                      />
                    </>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BusinessDirectoryDetail;
