import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { DealerApi } from "../Component/Api";
import axios from "axios";
import {
  Accordion,
  Breadcrumb,
  Carousel,
  Offcanvas,
} from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import Slider from "rc-slider";
import CategoryProductCard from "../Component/CategoryProductCard";
import { LiaThListSolid } from "react-icons/lia";
import { LuGrid } from "react-icons/lu";
import Cookies from "js-cookie";
import CategoryListing from "../Component/CategoryListing";
import Pagination from "../Component/Pagination.jsx";
import { Toast } from "react-bootstrap";
import megaphone from "../Images/megaphone-icon.png";
import { Helmet } from "react-helmet";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CarSearch = ({setting}) => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const slug = queryParams.get("cat");
  const subcat = queryParams.get("sub_cat");
  const countyUrl =
    queryParams.get("county") == null ? "all" : queryParams.get("county");
  const adType =
    queryParams.get("ad_type") == null ? "all" : queryParams.get("ad_type");
  const makeUrl =
    queryParams.get("make") == null ? "all" : queryParams.get("make");
  const modelUrl =
    queryParams.get("model") == null ? "all" : queryParams.get("model");
  const fuelUrl =
    queryParams.get("fuel") == null ? "all" : queryParams.get("fuel");
  const bodyTypeUrl =
    queryParams.get("body_type") == null ? "all" : queryParams.get("body_type");
  const colourUrl =
    queryParams.get("colour") == null ? "all" : queryParams.get("colour");
  const engineSizeUrl =
    queryParams.get("engine_size") == null
      ? "all"
      : queryParams.get("engine_size");
  const minYearUrl =
    queryParams.get("min_year") == null ? "all" : queryParams.get("min_year");
  const maxYearUrl =
    queryParams.get("max_year") == null ? "all" : queryParams.get("max_year");
  const minPriceUrl =
    queryParams.get("price_from") == null
      ? "all"
      : queryParams.get("price_from");
  const maxPriceUrl =
    queryParams.get("price_to") == null ? "all" : queryParams.get("price_to");
  const pageno = queryParams.get("page");

  const navigate = useNavigate();

  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);

  const [category, setCategory] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [account, setAccount] = useState([]);
  const [county, setCounty] = useState([]);
  const [makes, setMakes] = useState([]);
  const [model, setModel] = useState([]);
  const [fuel, setFuel] = useState([]);
  const [bodyType, setBodyType] = useState([]);
  const [colour, setColour] = useState([]);
  const [engineSize, setEngineSize] = useState([]);
  const [yearRange, setYearRange] = useState([]);
  const [firstYear, setFirstYear] = useState([]);

  const [priceRange, setPriceRange] = useState([]);
  const [firstPrice, setFirstPrice] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedCounty, setSelectedCounty] = useState("");
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedFuel, setSelectedFuel] = useState("");
  const [selectedBodyTypes, setSelectedBodyTypes] = useState("");
  const [selectedColour, setSelectedColour] = useState("");
  const [selectedEngineSize, setSelectedEngineSize] = useState("");
  const [selectedMinYear, setSelectedMinYear] = useState("");
  const [selectedMaxYear, setSelectedMaxYear] = useState("");
  const [selectedMinPrice, setSelectedMinPrice] = useState("");
  const [selectedMaxPrice, setSelectedMaxPrice] = useState("");

  const [selectedCategoryId, setSelectedCategoryId] = useState(slug);

  const [banner, setBanner] = useState([]);

  const [error, setError] = useState(null);

  const [sortingOption, setSortingOption] = useState("desc_score"); // Initial sorting option
  const [grid, setGrid] = useState(true);
  const [isGrid, setIsGrid] = useState(false);

  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState((pageno === null || pageno === undefined || pageno === "")?1:parseInt(pageno));
  const [totalAds, setTotalAds] = useState();
  const [perPage, setPerPage] = useState(16);
  const [totalPages, setTotalPages] = useState();

  const [wishlist, setWishlist] = useState([]);

  const [title, setTitle] = useState("");

  const [showCategoryAccordions, setShowCategoryAccordions] = useState(true);

  const [toastMessage, setToastMessage] = useState("");

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const [searchCategory, setSearchCategory] = useState("");
  const [searchSubCategory, setSearchSubCategory] = useState("");
  const [searchAccount, setSearchAccount] = useState("");
  const [searchCounty, setSearchCounty] = useState("");
  const [searchMake, setSearchMake] = useState("");
  const [searchModel, setSearchModel] = useState("");
  const [searchFuel, setSearchFuel] = useState("");
  const [searchBodyType, setSearchBodyType] = useState("");
  const [searchColour, setSearchColour] = useState("");
  const [searchEngineSize, setSearchEngineSize] = useState("");
  const [searchMinYear, setSearchMinYear] = useState("");
  const [searchMaxYear, setSearchMaxYear] = useState("");
  const [searchMinPrice, setSearchMinPrice] = useState("");
  const [searchMaxPrice, setSearchMaxPrice] = useState("");

  const [yearStatus, setYearStatus] = useState(true);
  const [priceStatus, setPriceStatus] = useState(true);

  const handleShowOffcanvas = () => setShowOffcanvas(true);
  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const [saveSearch, setSaveSearch] = useState(false);

  const [categorySlug, setCategorySlug] = useState("");
  const [subCategorySlug, setSubCategorySlug] = useState("");
  const [countySlug, setCountySlug] = useState("");
  const [accountSlug, setAccountSlug] = useState("");
  const [makeSlug, setMakeSlug] = useState("all");
  const [modelSlug, setModelSlug] = useState("all");
  const [fuelSlug, setFuelSlug] = useState("all");
  const [bodyTypeSlug, setBodyTypeSlug] = useState("all");
  const [colourSlug, setColourSlug] = useState("all");
  const [engineSizeSlug, setEngineSizeSlug] = useState("all");
  const [minYearSlug, setMinYearSlug] = useState("");
  const [maxYearSlug, setMaxYearSlug] = useState(new Date().getFullYear());
  const [minPriceSlug, setMinPriceSlug] = useState("");
  const [maxPriceSlug, setMaxPriceSlug] = useState("");
  const [load, setLoad] = useState(true);
  const [notFound, setNotFound] = useState(false);

  if (selectedSubCategory) {
    document.title = " - thedealer.ie your one stop shop for buying or selling goods online in Ireland, Northern Ireland"
  }

  useEffect(() => {
    setCategorySlug(slug);
    setSubCategorySlug(subcat);
    setCountySlug(countyUrl);
    setSelectedCounty(countyUrl=="all"? "" : countyUrl)
    setAccountSlug(adType);
    setSelectedAccount(adType=="all"? "" : adType)
    setMakeSlug(makeUrl);
    setSelectedMake(makeUrl=="all"? "" : makeUrl)
    setModelSlug(modelUrl);
    setSelectedModel(modelUrl=="all"? "" : modelUrl)
    setFuelSlug(fuelUrl);
    setSelectedFuel(fuelUrl=="all"? "" : fuelUrl)
    setBodyTypeSlug(bodyTypeUrl);
    setSelectedBodyTypes(bodyTypeUrl=="all"? "" : bodyTypeUrl)
    setColourSlug(colourUrl);
    setSelectedColour(colourUrl=="all"? "" : colourUrl)
    setEngineSizeSlug(engineSizeUrl);
    setSelectedEngineSize(engineSizeUrl=="all"? "" : engineSizeUrl)
    setMinYearSlug(minYearUrl);
    setSelectedMinYear(minYearUrl=="all"? "" : minYearUrl)
    setMaxYearSlug(maxYearUrl);
    setSelectedMaxYear(maxYearUrl=="all"? "" : maxYearUrl)
    setMinPriceSlug(minPriceUrl);
    setSelectedMinPrice(minPriceUrl=="all"? "" : minPriceUrl)
    setMaxPriceSlug(maxPriceUrl);
    setSelectedMaxPrice(maxPriceUrl=="all"? "" : maxPriceUrl)
  }, []);

  const toggleGrid = () => {
    setIsGrid(false);
    setGrid(true);
  };

  const toggleList = () => {
    setIsGrid(true);
    setGrid(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(
      `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=${minPriceSlug}&price_to=${maxPriceSlug}&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=${minYearSlug}&max_year=${maxYearSlug}&page=${page}`
    );
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageFromUrl = parseInt(searchParams.get("page")) || 1;

    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, currentPage]);

  useEffect(() => {
    axios
      .get(
        `${DealerApi}/filter/all?&category_slug=${
          slug == "newest_listing" ? "" : slug == "all" ? "" : slug
        }&subcategory_slug=${subcat == "all" ? "" : subcat}&make_slug=${
          makeUrl == "all" ? "" : makeUrl
        }&model_slug=${modelUrl == "all" ? "" : modelUrl}&fuel=${
          fuelUrl == "all" ? "" : fuelUrl
        }&body_type=${bodyTypeUrl == "all" ? "" : bodyTypeUrl}&engine_size=${
          engineSizeUrl == "all" ? "" : engineSizeUrl
        }&colour=${colourUrl == "all" ? "" : colourUrl}&min_year=${
          minYearUrl == "all" ? "" : minYearUrl
        }&max_year=${maxYearUrl == "all" ? "" : maxYearUrl}&min_price=${
          minPriceUrl == "all" ? "" : minPriceUrl
        }&max_price=${maxPriceUrl == "all" ? "" : maxPriceUrl}&county_slug=${
          countyUrl == "all" ? "" : countyUrl
        }&ad_type=${adType == "all" ? "" : adType}`
      )
      .then((response) => {
        setCategory(response.data.body.result.categories);
        setShowCategoryAccordions(false);

        const cat =
          response &&
          response.data.body.result.categories.find(
            (category) => category.slug === slug
          );

        setSelectedCategory(cat.slug);
        setSearchCategory({
          category_name: cat.category,
          category_slug: cat.slug,
        });

        setSelectedSubCategory(subcat);
        setSearchSubCategory({
          subcategory_name: subcat,
          subcategory_slug: subcat,
        });
        
        if (countyUrl != "all") {
          const countySelected =
            response &&
            response.data.body.result.counties.find(
              (category) => category.slug === countyUrl
            );
            if(countySelected){
          setSelectedCounty(countySelected.county);
          setSearchCounty({county_name: countySelected.county, county_slug: countySelected.slug})
            }
        }

        if (adType != "all") {
          setSelectedAccount(adType.replace(/and/g, "&").replace(/-/g, " "));
          setSearchAccount(adType.replace(/and/g, "&").replace(/-/g, " "))
        }
        
        if (makeUrl != "all" && response.data.body.result.makes.length > 0) {
          const countySelected =
            response &&
            response.data.body.result.makes.find(
              (category) => category.slug === makeUrl
            );
            if(countySelected){
              setSelectedMake(countySelected.make);
              setSearchMake({make_name: countySelected.make, make_id: countySelected.make_id, make_slug: countySelected.slug})
            }
        }

        if (modelUrl != "all" && response.data.body.result.model.length > 0) {
          const countySelected =
            response &&
            response.data.body.result.model.find(
              (category) => category.slug === modelUrl
            );
            if(countySelected){
              setSelectedModel(countySelected.model);
              setSearchModel({model_name: countySelected.model, model_id: countySelected.model_id, model_slug: countySelected.slug})
            }
        }
       
        if (fuelUrl != "all" && response.data.body.result.fuel.length > 0) {
          const countySelected =
            response &&
            response.data.body.result.fuel.find(
              (category) => category.fuel === fuelUrl
            );
            if(countySelected){
          setSelectedFuel(countySelected.fuel);
          setSearchFuel(countySelected.fuel)
            }
        }

        if (bodyTypeUrl != "all" && response.data.body.result.bodyTypes.length > 0) {
          const countySelected =
            response &&
            response.data.body.result.bodyTypes.find(
              (category) => category.body_type === bodyTypeUrl
            );
            if(countySelected){
          setSelectedBodyTypes(countySelected.body_type);
          setSearchBodyType(countySelected.body_type)
            }
        }

        if (colourUrl != "all" && response.data.body.result.colour.length > 0) {
          const countySelected =
            response &&
            response.data.body.result.colour.find(
              (category) => category.colour === colourUrl
            );
            if(countySelected){
          setSelectedColour(countySelected.colour);
          setSearchColour(countySelected.colour)
            }
        }

        if (
          engineSizeUrl != "all" && response.data.body.result.engineSize.length > 0
        ) {
          const countySelected =
            response &&
            response.data.body.result.engineSize.find(
              (category) => category.engine_size === engineSizeUrl
            );
            if(countySelected){
          setSelectedEngineSize(countySelected.engine_size);
          setSearchEngineSize(countySelected.engine_size)
            }
        }

        if (minYearUrl != "all" && response.data.body.result.year) {
          const countySelected =
            response &&
            response.data.body.result.year.find(
              (category) => category.min_year == minYearUrl
            );
            if(countySelected){
          setSelectedMinYear(countySelected.min_year);
          setSearchMinYear(countySelected.min_year)
            }
        }

        if (maxYearUrl != "all" && response.data.body.result.year) {
          const countySelected =
            response &&
            response.data.body.result.year.find(
              (category) => category.max_year == maxYearUrl
            );
            if(countySelected){
          setSelectedMaxYear(countySelected.max_year);
          setSearchMaxYear(countySelected.max_year)
            }
        }

        if (minPriceUrl != "all" && response.data.body.result.price) {
          const countySelected =
            response &&
            response.data.body.result.price.find(
              (category) => category.min_price == minPriceUrl
            );
            if(countySelected){
          setSelectedMinPrice(countySelected.min_price);
          setSearchMinPrice(countySelected.min_price)
            }
        }

        if (maxPriceUrl != "all" && response.data.body.result.price) {
          const countySelected =
            response &&
            response.data.body.result.price.find(
              (category) => category.max_price == maxPriceUrl
            );
            if(countySelected){
          setSelectedMaxPrice(countySelected.max_price);
          setSearchMaxPrice(countySelected.max_price)
            }
        }

          setShowCategoryAccordions(false);
          setYearRange([
            response.data.body.result.year[0].min_year,
            response.data.body.result.year[0].max_year,
          ]);
          if (yearStatus) {
            setFirstYear([
              response.data.body.result.year[0].min_year,
              response.data.body.result.year[0].max_year,
            ]);
            setYearStatus(false);
          }
         
          setPriceRange([
            response.data.body.result.price[0].min_price,
            response.data.body.result.price[0].max_price,
          ]);
          if (priceStatus) {
            setFirstPrice([
              response.data.body.result.price[0].min_price,
              response.data.body.result.price[0].max_price,
            ]);
            setPriceStatus(false);
          }
        setSubcategory(response.data.body.result.subcategories);
        setAccount(response.data.body.result.account);
        setCounty(response.data.body.result.counties);
        setMakes(response.data.body.result.makes);
        setModel(response.data.body.result.model);
        setFuel(response.data.body.result.fuel);
        setBodyType(response.data.body.result.bodyTypes);
        setColour(response.data.body.result.colour);
        setEngineSize(response.data.body.result.engineSize);
      })
      .catch((err) => {
      });
  }, [
    slug,
    subcat,
    countyUrl,
    adType,
    makeUrl,
    modelUrl,
    bodyTypeUrl,
    fuelUrl,
    colourUrl,
    engineSizeUrl,
    minYearUrl,
    maxYearUrl,
    minPriceUrl,
    maxPriceUrl,
  ]);

  useEffect(() => {
    setProduct([]);
    setLoad(true);
    axios
      .get(
        `${DealerApi}/listing?per_page=${perPage}&category_slug=${
          slug == "newest_listing" ? "" : slug == "all" ? "" : slug
        }&subcategory_slug=${subcat == "all" ? "" : subcat}&ad_type=${
          adType == "all" ? "" : adType
        }&county_slug=${
          countyUrl == "all" ? "" : countyUrl
        }&page_no=${currentPage}&make_slug=${
          makeUrl == "all" ? "" : makeUrl
        }&model_slug=${modelUrl == "all" ? "" : modelUrl}&fuel=${
          fuelUrl == "all" ? "" : fuelUrl
        }&body_type=${bodyTypeUrl == "all" ? "" : bodyTypeUrl}&engine_size=${
          engineSizeUrl == "all" ? "" : engineSizeUrl
        }&colour=${
          colourUrl == "all" ? "" : colourUrl
        }&sorted_by=${sortingOption}&min_year=${
          minYearUrl == "all" ? "" : minYearUrl
        }&max_year=${maxYearUrl == "all" ? "" : maxYearUrl}&min_price=${
          minPriceUrl == "all" ? "" : minPriceUrl
        }&max_price=${maxPriceUrl == "all" ? "" : maxPriceUrl}`
      )
      .then((response) => {
        setLoad(false);
        if (response.data.body.result.data.length === 0) {
          setNotFound(true);
          setTotalAds(0);
          setError("No Product Found.");
          // setSelectedMinPrice("");
          // setSelectedMaxPrice("");
        } else {
          setError(null);
          setNotFound(false);
          setTotalAds(response.data.body.result.total);
          if (
            slug == "newest_listing" &&
            selectedCounty == "" &&
            selectedAccount == ""
          ) {
            setSaveSearch(false);
          } else {
            setSaveSearch(true);
          }
        }
        setProduct(response.data.body.result.data);
        setBanner(response.data.body.result.banner);
        const calculatedTotalPages = Math.ceil(
          response.data.body.result.total / perPage
        );
        setTotalPages(calculatedTotalPages);
      })
      .catch((err) => {
        setLoad(false);
        if (err.response.data.body.success == 0) {
          setNotFound(true);
          setError("No Product Found.");
          setTotalAds(0);
        }
      });
  }, [
    slug,
    subcat,
    countyUrl,
    adType,
    makeUrl,
    modelUrl,
    bodyTypeUrl,
    fuelUrl,
    colourUrl,
    engineSizeUrl,
    minYearUrl,
    maxYearUrl,
    minPriceUrl,
    maxPriceUrl,
    currentPage,
    perPage,
    sortingOption,
  ]);

  const handleArrow = (catSlug) => {
    if (catSlug === selectedCategoryId) {
      setSelectedCategoryId(""); 
    } else {
      setSelectedCategoryId(catSlug);
    }
  };

  const handleCategory = (cat) => {
    setCategorySlug(cat.slug);
    setSubCategorySlug("all");
    setCurrentPage(1);
    navigate(
      `/search?cat=${cat.slug}&sub_cat=all&county=${countySlug}&ad_type=${accountSlug}`
    );
    // if (slug === selectedCategoryId) {
    //   setSubcategory("");
    // }
    setSelectedCategoryId(cat.slug);
    setSelectedCategory(cat.category);
    // setSelectedSubCategory("");
    setSearchCategory({ category_name: cat.category, category_slug: cat.slug });
    setSortingOption("desc_score");
  };

  const handleSubCategory = (sub) => {
    setSubCategorySlug(sub.slug);
    setSelectedSubCategory(sub.category);
    setCurrentPage(1);
    navigate(
      `/search?cat=${categorySlug}&sub_cat=${sub.slug}&county=${countySlug}&ad_type=${accountSlug}`
    );
    setSearchSubCategory({
      subcategory_name: sub.category,
      subcategory_slug: sub.slug,
    });
    setSortingOption("desc_score");
  };

  const handleAccountClick = (acc) => {
    setAccountSlug(acc);
    setCurrentPage(1);
    navigate(
      `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${acc}&make=${makeSlug}&model=${modelSlug}&price_from=${minPriceSlug}&price_to=${maxPriceSlug}&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=${minYearSlug}&max_year=${maxYearSlug}`
    );
    setSelectedAccount(acc);
    setSearchAccount(acc);
    setSortingOption("desc_score");
  };

  const handleCountyClick = (county) => {
    setCountySlug(county.slug);
    setCurrentPage(1);
    navigate(
      `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${county.slug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=${minPriceSlug}&price_to=${maxPriceSlug}&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=${minYearSlug}&max_year=${maxYearSlug}`
    );
    setSelectedCounty(county.county);
    setSearchCounty({ county_name: county.county, county_slug: county.slug });
    setSortingOption("desc_score");
  };

  const handleMakeClick = (make) => {
    setSelectedMake(make.slug);
    setMakeSlug(make.slug);
    setCurrentPage(1);
    navigate(
      `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${make.slug}&model=all&price_from=${minPriceSlug}&price_to=${maxPriceSlug}&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=${minYearSlug}&max_year=${maxYearSlug}`
    );
    setSearchMake({
      make_name: make.make,
      make_id: make.make_id,
      make_slug: make.slug,
    });
    setSortingOption("desc_score");
  };

  const handleModelClick = (model) => {
    setSelectedModel(model.slug);
    setModelSlug(model.slug);
    setCurrentPage(1);
    navigate(
      `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${model.slug}&price_from=${minPriceSlug}&price_to=${maxPriceSlug}&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=${minYearSlug}&max_year=${maxYearSlug}`
    );
    setSearchModel({
      model_name: model.model,
      model_id: model.model_id,
      model_slug: model.slug,
    });
    setSortingOption("desc_score");
  };

  const handleFuelClick = (fuel) => {
    setSelectedFuel(fuel);
    setFuelSlug(fuel);
    setCurrentPage(1);
    navigate(
      `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=${minPriceSlug}&price_to=${maxPriceSlug}&fuel=${fuel}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=${minYearSlug}&max_year=${maxYearSlug}`
    );
    setSearchFuel(fuel);
    setSortingOption("desc_score");
  };

  const handleBodyTypesClick = (bodytypes) => {
    setSelectedBodyTypes(bodytypes);
    setBodyTypeSlug(bodytypes);
    setCurrentPage(1);
    navigate(
      `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=${minPriceSlug}&price_to=${maxPriceSlug}&fuel=${fuelSlug}&body_type=${bodytypes}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=${minYearSlug}&max_year=${maxYearSlug}`
    );
    setSearchBodyType(bodytypes);
    setSortingOption("desc_score");
  };

  const handleColorsClick = (color) => {
    setSelectedColour(color);
    setColourSlug(color);
    setCurrentPage(1);
    navigate(
      `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=${minPriceSlug}&price_to=${maxPriceSlug}&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${color}&engine_size=${engineSizeSlug}&min_year=${minYearSlug}&max_year=${maxYearSlug}`
    );
    setSearchColour(color);
    setSortingOption("desc_score");
  };

  const handleEngineSizeClick = (enginesize) => {
    setSelectedEngineSize(enginesize);
    setEngineSizeSlug(enginesize);
    setCurrentPage(1);
    navigate(
      `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=${minPriceSlug}&price_to=${maxPriceSlug}&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${enginesize}&min_year=${minYearSlug}&max_year=${maxYearSlug}`
    );
    setSearchEngineSize(enginesize);
    setSortingOption("desc_score");
  };

  const handleYearRangeChange = (values) => {
    setYearStatus(false);
    setYearRange(values);
    setSelectedMinYear(values[0]);
    setSelectedMaxYear(values[1]);
    setMinYearSlug(values[0]);
    setMaxYearSlug(values[1]);
    setCurrentPage(1);
    navigate(
      `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=${values[0]}&max_year=${values[1]}`
    );
    setSearchMinYear(values[0]);
    setSearchMaxYear(values[1]);
    setSelectedMinPrice("");
    setSelectedMaxPrice("");
    setSearchMinPrice("");
    setSearchMaxPrice("");
    setSortingOption("desc_score");
  };

  const handlePriceRangeChange = (values) => {
    setPriceStatus(false);
    setPriceRange(values);
    setSelectedMinPrice(values[0]);
    setSelectedMaxPrice(values[1]);
    setMinPriceSlug(values[0]);
    setMaxPriceSlug(values[1]);
    setCurrentPage(1);
    navigate(
      `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=${values[0]}&price_to=${values[1]}&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
    );
    setSearchMinPrice(values[0]);
    setSearchMaxPrice(values[1]);
    setSelectedMinYear("");
    setSelectedMaxYear("");
    setSearchMinYear("");
    setSearchMaxYear("");
    setSortingOption("desc_score");
  };

  const handleBannerClick = (link) => {
    window.open(link, "_blank");
  };

  const handleTooltip = (value) => {
    return `$${value.toFixed(2)}`;
  };

  const handleBreadcrumbClick = async (
    category,
    subcategory,
    selectedMake,
    selectedModel,
    selectedFuel,
    selectedBodyType,
    selectedColors,
    selectedEngineSize
  ) => {
    setPerPage(16);
    setSortingOption("desc_score");
    if (selectedEngineSize) {
      setSelectedCounty("");
      setSelectedAccount("");
      setSelectedFuel("");
      setSelectedMake("");
      setSelectedModel("");
      setSelectedBodyTypes("");
      setSelectedColour("");
      setSelectedMinPrice("");
      setSelectedMaxPrice("");
      setSelectedMinYear("");
      setSelectedMaxYear("");
      setCurrentPage(1);
      navigate(
        `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&engine_size=${engineSizeSlug}&county=all`
      );

      setSelectedCounty("");
      setSearchCounty("");
    } else if (selectedColors) {
      setSelectedCounty("");
      setSelectedAccount("");
      setSelectedFuel("");
      setSelectedMake("");
      setSelectedModel("");
      setSelectedBodyTypes("");
      setSelectedEngineSize("");
      setSelectedMinPrice("");
      setSelectedMaxPrice("");
      setSelectedMinYear("");
      setSelectedMaxYear("");
      setCurrentPage(1);
      navigate(
        `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&colour=${colourSlug}&engine_size=all&county=all`
      );
    } else if (selectedBodyType) {
      setSelectedCounty("");
      setSelectedAccount("");
      setSelectedFuel("");
      setSelectedMake("");
      setSelectedModel("");
      setSelectedEngineSize("");
      setSelectedColour("");
      setSelectedMinPrice("");
      setSelectedMaxPrice("");
      setSelectedMinYear("");
      setSelectedMaxYear("");
      setCurrentPage(1);
      navigate(
        `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&body_type=${bodyTypeSlug}&colour=all&engine_size=all&county=all`
      );
    } else if (selectedFuel) {
      setSelectedCounty("");
      setSelectedAccount("");
      setSelectedEngineSize("");
      setSelectedMake("");
      setSelectedModel("");
      setSelectedBodyTypes("");
      setSelectedColour("");
      setSelectedMinPrice("");
      setSelectedMaxPrice("");
      setSelectedMinYear("");
      setSelectedMaxYear("");
      setCurrentPage(1);
      navigate(
        `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&fuel=${fuelSlug}&body_type=all&colour=all&engine_size=all&county=all`
      );
    } else if (selectedModel) {
      setSelectedCounty("");
      setSelectedAccount("");
      setSelectedFuel("");
      setSelectedEngineSize("");
      setSelectedBodyTypes("");
      setSelectedColour("");
      setSelectedMinPrice("");
      setSelectedMaxPrice("");
      setSelectedMinYear("");
      setSelectedMaxYear("");
      setCurrentPage(1);
      navigate(
        `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&make=${makeSlug}&model=${modelSlug}&fuel=all&body_type=all&colour=all&engine_size=all&county=all`
      );
    } else if (selectedMake) {
      setSelectedCounty("");
      setSelectedAccount("");
      setSelectedFuel("");
      setSelectedEngineSize("");
      setMakes("");
      setSelectedModel("");
      setSelectedBodyTypes("");
      setSelectedColour("");
      setSelectedMinPrice("");
      setSelectedMaxPrice("");
      setSelectedMinYear("");
      setSelectedMaxYear("");
      setCurrentPage(1);
      navigate(
        `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&make=${makeSlug}&model=all&fuel=all&body_type=all&colour=all&engine_size=all&county=all`
      );
    } else if (subcategory) {
      setSelectedColour("");
      setSelectedModel("");
      setSelectedMake("");
      setSelectedFuel("");
      setSelectedCounty("");
      setSelectedEngineSize("");
      setSelectedBodyTypes("");
      setSearchCounty("");
      setSearchEngineSize("");
      setSearchColour("");
      setSearchBodyType("");
      setSearchFuel("");
      setSearchModel("");
      setSearchMake("");
      setCurrentPage(1);
      navigate(`/${slug}/${subcat}`);
    } else {
      setSelectedMake("");
      setSelectedModel("");
      setSelectedFuel("");
      setSelectedBodyTypes("");
      setSelectedEngineSize("");
      setSelectedColour("");
      setSelectedMinPrice("");
      setSelectedMaxPrice("");
      setSelectedMinYear("");
      setSelectedMaxYear("");
      setSelectedAccount("");
      setSelectedCounty("");
      setSearchCounty("");
      setSearchEngineSize("");
      setSearchColour("");
      setSearchBodyType("");
      setSearchFuel("");
      setSearchModel("");
      setSearchMake("");
      setSearchSubCategory("");
      setSelectedSubCategory("");
      setTitle(category.category);
      setCurrentPage(1);
      navigate(`/${slug}`);
    }
  };

  const handleRemove = (type) => {
    setCurrentPage(1);
    setPerPage(16);
    setSortingOption("desc_score");

    switch (type) {
      case "subcategory":
        setSelectedCounty("");
        setSelectedAccount("");
        setSelectedMake("");
        setSelectedModel("");
        setSelectedFuel("");
        setSelectedBodyTypes("");
        setSelectedEngineSize("");
        setSelectedColour("");
        setSelectedMinPrice("");
        setSelectedMaxPrice("");
        setSelectedMinYear("");
        setSelectedMaxYear("");
        setSelectedSubCategory("");
        setSearchSubCategory("");
        setCurrentPage(1);
        navigate(`/${slug}`);
        break;
      case "account":
        setSelectedAccount("");
        setSearchAccount("");
        setAccountSlug("all");
        setAccountSlug("all");
        setCurrentPage(1);
        navigate(
          `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=all&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
        );
        break;
      case "county":
        setSelectedCounty("");
        setSearchCounty("");
        setCountySlug("all");
        setCurrentPage(1);
        navigate(
          `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=all&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
        );
        break;
      case "make":
        setSelectedMake("");
        setSelectedModel("");
        setSearchMake("");
        setSearchModel("");
        setMakeSlug("all");
        setModelSlug("all");
        setSelectedMake("");
        setSelectedModel("");
        setCurrentPage(1);
        navigate(
          `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=all&model=all&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
        );
        break;
      case "model":
        setSelectedModel("");
        setSearchModel("");
        setModelSlug("all");
        setCurrentPage(1);
        navigate(
          `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=all&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
        );
        break;
      case "fuel":
        setSelectedFuel("");
        setSearchFuel("");
        setFuelSlug("all");
        setCurrentPage(1);
        navigate(
          `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=all&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
        );
        break;
      case "bodytypes":
        setSelectedBodyTypes("");
        setSearchBodyType("");
        setBodyTypeSlug("all");
        setCurrentPage(1);
        navigate(
          `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=all&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
        );
        break;
      case "enginesize":
        setSelectedEngineSize("");
        setSearchEngineSize("");
        setEngineSizeSlug("all");
        setCurrentPage(1);
        navigate(
          `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=all&min_year=all&max_year=all`
        );
        break;
      case "color":
        setSelectedColour("");
        setSearchColour("");
        setColourSlug("all");
        setCurrentPage(1);
        navigate(
          `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=all&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
        );
        break;
      case "minyear":
        setYearStatus(true);
        setPriceStatus(true);
        setSelectedMinYear("");
        setSelectedMaxYear("");
        setSearchMinYear("");
        setSearchMaxYear("");
        setMinYearSlug("all");
        setMaxYearSlug("all");
        setCurrentPage(1);
        navigate(
          `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
        );
        setYearRange(firstYear);
        break;
      case "maxyear":
        setYearStatus(true);
        setPriceStatus(true);
        setSelectedMinYear("");
        setSelectedMaxYear("");
        setSearchMaxYear("");
        setSearchMinYear("");
        setMinYearSlug("all");
        setMaxYearSlug("all");
        setCurrentPage(1);
        navigate(
          `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
        );
        setYearRange(firstYear);
        break;
      case "minprice":
        setPriceStatus(true);
        setYearStatus(true);
        setSelectedMinPrice("");
        setSelectedMaxPrice("");
        setSearchMinPrice("");
        setSearchMaxPrice("");
        setMinPriceSlug("all");
        setMaxPriceSlug("all");
        setCurrentPage(1);
        navigate(
          `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
        );
        setPriceRange(firstPrice);
        break;
      case "maxprice":
        setPriceStatus(true);
        setYearStatus(true);
        setSelectedMinPrice("");
        setSelectedMaxPrice("");
        setSearchMinPrice("");
        setSearchMaxPrice("");
        setMinPriceSlug("all");
        setMaxPriceSlug("all");
        setCurrentPage(1);
        navigate(
          `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
        );
        setPriceRange(firstPrice);
        break;
      default:
        break;
    }
  };

  const wishListFunction = async () => {
    if (tokenObject) {
      await axios
        .get(`${DealerApi}/wishlist/${tokenObject.id}`)
        .then((res) => {
          setWishlist(res.data.body.result.data);
        })
        .catch((err) => {
      });
    }
  };

  // useEffect(() => {
  //   wishListFunction()
  // },[])

  const isInWishlist = (itemId) => {
    return wishlist && wishlist.some((item) => item.id == itemId);
  };
 
  const updateWishlist = async () => {
    await wishListFunction();

    // const updatedProducts = product.map((product) => {
    //   product.isInWishlist = isInWishlist(product.id);
    //   return product;
    // });
    // // setProduct(updatedProducts);
  };

  useEffect(() => {
    updateWishlist();
  }, []);
  
  const handleSaveSearch = () => {
    if (!tokenObject || !tokenObject.token || !tokenObject.id) {
      navigate("/login");
    } 
    else {
      axios
        .post(
          `${DealerApi}/search/store`,
          {
            search_key_word: "",
            user_id: tokenObject && tokenObject.id,
            filters: {
              category: searchCategory && searchCategory.category_name,
              category_slug: searchCategory && searchCategory.category_slug,
              subcategory:
                searchSubCategory && searchSubCategory.subcategory_name,
              subcategory_slug:
                searchSubCategory && searchSubCategory.subcategory_slug,
              county: searchCounty && searchCounty.county_name,
              county_slug: searchCounty && searchCounty.county_slug,
              body_type: searchBodyType,
              model_id: searchModel && searchModel.model_id,
              model: searchModel && searchModel.model_name,
              model_slug: searchModel && searchModel.model_slug,
              make_id: searchMake && searchMake.make_id,
              make: searchMake && searchMake.make_name,
              make_slug: searchMake && searchMake.make_slug,
              engine_size: searchEngineSize,
              min_year: searchMinYear,
              min_price: searchMinPrice,
              max_price: searchMaxPrice,
              ad_type: searchAccount,
              max_year: searchMaxYear,
              fuel: searchFuel,
              colour: searchColour,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${tokenObject && tokenObject.token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setToastMessage("Successfully save in search page");
          setTimeout(() => {
            setToastMessage("");
          }, 5000);
        })
        .catch((e) => {
        });
    }
  };

  const handleAllCars = (item) => {
    if (item == "county") {
      setSelectedCounty("");
      setCountySlug("all");
      navigate(
        `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=all&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
      );
    } else if (item == "account") {
      setSelectedAccount("");
      setAccountSlug("all");
      navigate(
        `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=all&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
      );
    } else if (item == "make") {
      setSelectedMake("");
      setSelectedModel("");
      setModelSlug("all");
      setMakeSlug("all");
      navigate(
        `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=all&model=all&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
      );
    } else if (item == "model") {
      setSelectedModel("");
      setModelSlug("all");
      navigate(
        `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=all&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
      );
    } else if (item == "fuel") {
      setSelectedFuel("");
      setFuelSlug("all");
      navigate(
        `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=all&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
      );
    } else if (item == "bodyType") {
      setSelectedBodyTypes("");
      setBodyTypeSlug("all");
      navigate(
        `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=all&colour=${colourSlug}&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
      );
    } else if (item == "color") {
      setSelectedColour("");
      setColourSlug("all");
      navigate(
        `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=all&engine_size=${engineSizeSlug}&min_year=all&max_year=all`
      );
    } else if (item == "engineSize") {
      setSelectedEngineSize("");
      setEngineSizeSlug("all");
      navigate(
        `/car_search?cat=${categorySlug}&sub_cat=${subCategorySlug}&county=${countySlug}&ad_type=${accountSlug}&make=${makeSlug}&model=${modelSlug}&price_from=all&price_to=all&fuel=${fuelSlug}&body_type=${bodyTypeSlug}&colour=${colourSlug}&engine_size=all&min_year=all&max_year=all`
      );
    }
  };

  return (
    <>
    <Helmet>
        <meta
          name="description"
          content={`${subcat=="all"?selectedCategory:selectedSubCategory.charAt(0).toUpperCase()+ selectedSubCategory.slice(1).toLowerCase()} - Buy or sell anything online in Ireland, Northern, cars, furniture,household, agricultural, electrical, music, holidays, wedding, fashion, farming` }
        />
        <meta
          name="keywords"
          content={`${subcat=="all"?selectedCategory:selectedSubCategory.charAt(0).toUpperCase()+ selectedSubCategory.slice(1).toLowerCase()} - thedealer.ie your one stop shop for buying or selling goods online in Ireland, Northern Ireland` }
        />
      </Helmet>
      <div className="category_page_main">
        <div className="container">
          <div className="row">
            <Breadcrumb>
              <Breadcrumb.Item href="/" className="home_breadcrumb">
                Home
              </Breadcrumb.Item>

              {selectedCategory && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick({ slug: category, category: title })
                  }
                >
                  {selectedCategory}
                </Breadcrumb.Item>
              )}

              {selectedSubCategory && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      { slug: subcategory, category: selectedSubCategory }
                    )
                  }
                >
                  {selectedSubCategory}
                </Breadcrumb.Item>
              )}

              {selectedMake && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      {
                        slug: subcategory,
                        category: selectedSubCategory,
                      },
                      selectedMake
                    )
                  }
                >
                  {selectedMake}
                </Breadcrumb.Item>
              )}

              {selectedModel && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      {
                        slug: subcategory,
                        category: selectedSubCategory,
                      },
                      null,
                      selectedModel
                    )
                  }
                >
                  {selectedModel}
                </Breadcrumb.Item>
              )}

              {selectedFuel && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      {
                        slug: subcategory,
                        category: selectedSubCategory,
                      },
                      null,
                      null,
                      selectedFuel
                    )
                  }
                >
                  {selectedFuel}
                </Breadcrumb.Item>
              )}

              {selectedBodyTypes && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      {
                        slug: subcategory,
                        category: selectedSubCategory,
                      },
                      null,
                      null,
                      null,
                      selectedBodyTypes
                    )
                  }
                >
                  {selectedBodyTypes}
                </Breadcrumb.Item>
              )}

              {selectedColour && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      {
                        slug: subcategory,
                        category: selectedSubCategory,
                      },
                      null,
                      null,
                      null,
                      null,
                      selectedColour
                    )
                  }
                >
                  {selectedColour}
                </Breadcrumb.Item>
              )}

              {selectedEngineSize && (
                <Breadcrumb.Item
                  className="active"
                  onClick={() =>
                    handleBreadcrumbClick(
                      { slug: category, category: title },
                      {
                        slug: subcategory,
                        category: selectedSubCategory,
                      },
                      null,
                      null,
                      null,
                      null,
                      null,
                      selectedEngineSize
                    )
                  }
                >
                  {selectedEngineSize}
                </Breadcrumb.Item>
              )}

              {selectedCounty && (
                <Breadcrumb.Item
                  className="active"
                >
                  {selectedCounty}
                </Breadcrumb.Item>
              )}
            </Breadcrumb>

            <div className="col-lg-3 col-md-4 d-lg-block aside">
              {selectedSubCategory || selectedAccount || selectedCounty ? (
                <div className="selected_item_div">
                  <div className="selected_item_div">
                    <span className="selected_heading">Your Selection:</span>
                  </div>

                  {selectedSubCategory && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Subcategory:</span>{" "}
                        <span className="selected_div">
                          {selectedSubCategory}
                        </span>
                      </p>
                      <button
                        onClick={() => handleRemove("subcategory")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedAccount && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Account:</span>{" "}
                        <span className="selected_div">{selectedAccount}</span>
                      </p>
                      <button
                        onClick={() => handleRemove("account")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedCounty && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">County:</span>{" "}
                        <span className="selected_div">{selectedCounty}</span>
                      </p>
                      <button
                        onClick={() => handleRemove("county")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedMake && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Make:</span>{" "}
                        <span className="selected_div">{selectedMake}</span>
                      </p>
                      <button
                        onClick={() => handleRemove("make")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedModel && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Model:</span>{" "}
                        <span className="selected_div">{selectedModel}</span>
                      </p>
                      <button
                        onClick={() => handleRemove("model")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedFuel && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Fuel:</span>{" "}
                        <span className="selected_div">{selectedFuel}</span>
                      </p>
                      <button
                        onClick={() => handleRemove("fuel")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedBodyTypes && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Body Type:</span>{" "}
                        <span className="selected_div">
                          {selectedBodyTypes}
                        </span>
                      </p>
                      <button
                        onClick={() => handleRemove("bodytypes")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedEngineSize && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Engine Size:</span>{" "}
                        <span className="selected_div">
                          {selectedEngineSize}
                        </span>
                      </p>
                      <button
                        onClick={() => handleRemove("enginesize")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedColour && (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Colour:</span>{" "}
                        <span className="selected_div">{selectedColour}</span>
                      </p>
                      <button
                        onClick={() => handleRemove("color")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  )}

                  {selectedMinYear ? (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Min Year:</span>{" "}
                        {selectedMinYear}
                      </p>
                      <button
                        onClick={() => handleRemove("minyear")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    selectedMinYear === 0 && (
                      <div className="selected_item_category">
                        <p>
                          <span className="selected_item">Min Year:</span>{" "}
                          {selectedMinYear}
                        </p>
                        <button
                          onClick={() => handleRemove("minyear")}
                          className="cancel_btn"
                        >
                          X
                        </button>
                      </div>
                    )
                  )}

                  {selectedMaxYear ? (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Max Year:</span>{" "}
                        {selectedMaxYear}
                      </p>
                      <button
                        onClick={() => handleRemove("maxyear")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    selectedMaxYear === 0 && (
                      <div className="selected_item_category">
                        <p>
                          <span className="selected_item">Max Year:</span>{" "}
                          {selectedMaxYear}
                        </p>
                        <button
                          onClick={() => handleRemove("maxyear")}
                          className="cancel_btn"
                        >
                          X
                        </button>
                      </div>
                    )
                  )}

                  {selectedMinPrice ? (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Min Price:</span>{" "}
                        {selectedMinPrice}
                      </p>
                      <button
                        onClick={() => handleRemove("minprice")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    selectedMinPrice === 0 && (
                      <div className="selected_item_category">
                        <p>
                          <span className="selected_item">Min Price:</span>{" "}
                          {selectedMinPrice}
                        </p>
                        <button
                          onClick={() => handleRemove("minprice")}
                          className="cancel_btn"
                        >
                          X
                        </button>
                      </div>
                    )
                  )}

                  {selectedMaxPrice ? (
                    <div className="selected_item_category">
                      <p>
                        <span className="selected_item">Max Price:</span>{" "}
                        {selectedMaxPrice}
                      </p>
                      <button
                        onClick={() => handleRemove("maxprice")}
                        className="cancel_btn"
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    selectedMaxPrice === 0 && (
                      <div className="selected_item_category">
                        <p>
                          <span className="selected_item">Max Price:</span>{" "}
                          {selectedMaxPrice}
                        </p>
                        <button
                          onClick={() => handleRemove("maxprice")}
                          className="cancel_btn"
                        >
                          X
                        </button>
                      </div>
                    )
                  )}
                </div>
              ) : null}

              <div
                className={`product_sidebar pt-20 ${
                  showOffcanvas ? "d-none d-md-block d-sm-block" : "" // Hide for smaller screens when Offcanvas is open
                }`}
              >
                <Accordion defaultActiveKey="0">
                  {showCategoryAccordions &&
                    category &&
                    category.length > 0 && (
                      <Accordion.Item eventKey="0">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Categories</h5>
                            </Accordion.Header>
                          </div>

                          <Accordion.Body className="accordion_body">
                            <div className="categories-container">
                              <p
                                className="category"
                                onClick={() => {
                                  handleAllCars("category");
                                }}
                              >
                                Categories (All)
                              </p>
                              {category.map((categories) => (
                                <div key={categories.id} className="category">
                                  <p onClick={() => handleCategory(categories)}>
                                    <IoIosArrowForward onClick={(e) => {
                                          e.stopPropagation(); // Prevent the category click event from firing
                                          handleArrow(categories.slug);
                                        }}/> {categories.category}
                                  </p>
                                  {selectedCategoryId === categories.slug &&
                                    subcategory && (
                                      <ul className="subcategories">
                                        {subcategory.map((subcategories) => (
                                          <li
                                            key={subcategories.id}
                                            onClick={() =>
                                              handleSubCategory(subcategories)
                                            }
                                          >
                                            {subcategories.category} (
                                            {subcategories.listing_count})
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                </div>
                              ))}
                            </div>
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>
                    )}

                  {(subcat == "cars" || subcat == "4x4-wheel-drive") && (
                    <>
                      <Accordion.Item eventKey="3" className="accordion_space">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Makes</h5>
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="accordion_body">
                            <p
                              className="category"
                              onClick={() => {
                                handleAllCars("make");
                              }}
                            >
                              Makes (All)
                            </p>
                            {makes &&
                              makes.length > 0 &&
                              makes.map((make) => (
                                <>
                                  <p
                                    key={make.id}
                                    className="category"
                                    onClick={() => handleMakeClick(make)}
                                  >
                                    {make.make} ({make.listing_count})
                                  </p>
                                </>
                              ))}
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>
                      {makeUrl != "all" && (
                        <div className="models-container">
                          <h5 className="title_model">Models</h5>
                          <p
                            className="category_model"
                            onClick={() => {
                              handleAllCars("model");
                            }}
                          >
                            Models (All)
                          </p>
                          {(model && model.length>0) && model.map((model) => (
                            <p
                              key={model.id}
                              className="category_model"
                              onClick={() => handleModelClick(model)}
                            >
                              {model.model} ({model.listing_count})
                            </p>
                          ))}
                        </div>
                      )}
                    </>
                  )}

                  <Accordion.Item eventKey="1" className="accordion_space">
                    <div className="sidebar_categories mt-10">
                      <div className="sidebar_title">
                        <Accordion.Header>
                          <h5 className="category_title">Account</h5>
                        </Accordion.Header>
                      </div>
                      <Accordion.Body className="accordion_body">
                        <p
                          className="category"
                          onClick={() => {
                            handleAllCars("account");
                          }}
                        >
                          Account (All)
                        </p>

                        {account &&
                          account.length > 0 &&
                          account.map((account) => (
                            <div key={account.id} className="category">
                              <p
                                onClick={() =>
                                  handleAccountClick(account.ad_type)
                                }
                                className="account_title"
                              >
                                {account.ad_type} ({account.listing_count})
                              </p>
                            </div>
                          ))}
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2" className="accordion_space">
                    <div className="sidebar_categories mt-10">
                      <div className="sidebar_title">
                        <Accordion.Header>
                          <h5 className="category_title">County</h5>
                        </Accordion.Header>
                      </div>
                      <Accordion.Body className="accordion_body">
                        <p
                          className="category"
                          onClick={() => {
                            handleAllCars("county");
                          }}
                        >
                          Counties (All)
                        </p>
                        {county &&
                          county.length > 0 &&
                          county.map((county) => (
                            <p
                              key={county.id}
                              className="category"
                              onClick={() => handleCountyClick(county)}
                            >
                              {county.county} ({county.listing_count})
                            </p>
                          ))}
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>

                  {(subcat == "cars" || subcat == "4x4-wheel-drive") && (
                    <>
                      <Accordion.Item eventKey="4" className="accordion_space">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Fuels</h5>
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="accordion_body">
                            <p
                              className="account_title"
                              onClick={() => {
                                handleAllCars("fuel");
                              }}
                            >
                              Fuel Type (All)
                            </p>
                            {fuel &&
                              fuel.length > 0 &&
                              fuel.map((fuel) => (
                                <p
                                  key={fuel.id}
                                  className="account_title"
                                  onClick={() => handleFuelClick(fuel.fuel)}
                                >
                                  {fuel.fuel} ({fuel.listing_count})
                                </p>
                              ))}
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>

                      <Accordion.Item eventKey="5" className="accordion_space">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Body Type</h5>
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="accordion_body">
                            <p
                              className="account_title"
                              onClick={() => {
                                handleAllCars("bodyType");
                              }}
                            >
                              Body Type (All)
                            </p>
                            {bodyType &&
                              bodyType.length > 0 &&
                              bodyType.map((bodytype) => (
                                <p
                                  key={bodytype.id}
                                  className="account_title"
                                  onClick={() =>
                                    handleBodyTypesClick(bodytype.body_type)
                                  }
                                >
                                  {bodytype.body_type} ({bodytype.listing_count}
                                  )
                                </p>
                              ))}
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>

                      <Accordion.Item eventKey="6" className="accordion_space">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Colour</h5>
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="accordion_body">
                            <p
                              className="category"
                              onClick={() => {
                                handleAllCars("color");
                              }}
                            >
                              All
                            </p>
                            <div className="category color-grid">
                              {colour &&
                                colour.length > 0 &&
                                colour.map((item) => {
                                  const color = item.colour;
                                  if (color) {
                                    return (
                                      <div
                                        key={item.id || item.colour}
                                        className="color_spilt_div"
                                      >
                                        <div className="color">
                                          <div
                                            className="color_div"
                                            style={{
                                              backgroundColor:
                                                color === "burgundy"
                                                  ? "#800020"
                                                  : color === "bronze"
                                                  ? "#CD7F32"
                                                  : color,
                                            }}
                                            onClick={() =>
                                              handleColorsClick(color)
                                            }
                                          ></div>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>

                      <Accordion.Item eventKey="7" className="accordion_space">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Engine Size</h5>
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="accordion_body">
                            <p
                              className="category"
                              onClick={() => {
                                handleAllCars("engineSize");
                              }}
                            >
                              Engine Size (All)
                            </p>
                            {engineSize &&
                              engineSize.length > 0 &&
                              engineSize.map((enginesize) => (
                                <p
                                  key={enginesize.id}
                                  className="category"
                                  onClick={() =>
                                    handleEngineSizeClick(
                                      enginesize.engine_size
                                    )
                                  }
                                >
                                  {enginesize.engine_size} (
                                  {enginesize.listing_count})
                                </p>
                              ))}
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>

                      {(subcat=="cars" || subcat=="4x4-wheel-drive")&& (
                          <Accordion.Item
                            eventKey="8"
                            className="accordion_space"
                          >
                            <div className="sidebar_categories mt-10">
                              <div className="sidebar_title">
                                <Accordion.Header>
                                  <h5 className="category_title">Year</h5>
                                </Accordion.Header>
                              </div>
                              <Accordion.Body className="accordion_body">
                                <div>
                                  <label>Filter By: Year</label>

                                  <Slider
                                    key={yearRange.join("-")}
                                    range
                                    min={firstYear[0]}
                                    max={firstYear[1]}
                                    step={1}
                                    defaultValue={yearRange}
                                    onChangeComplete={handleYearRangeChange}
                                    allowCross={false}
                                  />

                                  <div className="slider_label">
                                    <span>
                                      {yearRange[0] === null
                                        ? "0"
                                        : yearRange[0]}
                                    </span>
                                    <span>
                                      {yearRange[1] === null
                                        ? "0"
                                        : yearRange[1]}
                                    </span>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </div>
                          </Accordion.Item>
                        )}

                      {(subcat=="cars" || subcat=="4x4-wheel-drive")&& (
                          <Accordion.Item
                            eventKey="9"
                            className="accordion_space"
                          >
                            <div className="sidebar_categories mt-10">
                              <div className="sidebar_title">
                                <Accordion.Header>
                                  <h5 className="category_title">Price</h5>
                                </Accordion.Header>
                              </div>
                              <Accordion.Body className="accordion_body">
                                <div>
                                  <label>Filter By: Price</label>
                                  <Slider
                                    key={priceRange.join("-")}
                                    range
                                    min={firstPrice[0]}
                                    max={firstPrice[1]}
                                    step={1}
                                    defaultValue={priceRange}
                                    onChangeComplete={handlePriceRangeChange}
                                    tipFormatter={handleTooltip}
                                    allowCross={false}
                                  />
                                  <div className="slider_label">
                                    <span>
                                      {priceRange[0] === null
                                        ? "0"
                                        : priceRange[0]}
                                    </span>
                                    <span>
                                      {priceRange[1] === null
                                        ? "0"
                                        : priceRange[1]}
                                    </span>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </div>
                          </Accordion.Item>
                        )
                      }
                    </>
                  )}
                </Accordion>
              </div>
            </div>

            <div className="col-lg-9 col-md-12">
              {banner && banner.length > 0 && (
                <div className="carousel_card1 text-center">
                  <div className="carousel-container">
                    <Carousel
                      controls={false}
                      indicators={false}
                      interval={5000}
                    >
                      {banner.map((item, index) => (
                        <Carousel.Item
                          key={index}
                          onClick={() => handleBannerClick(item.banner_link)}
                        >
                          <img
                            className="feature_img"
                            src={item.url}
                            alt={item.banner_name}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                </div>
              )}

              <Toast
                show={toastMessage !== ""}
                onClose={() => setToastMessage("")}
                className="fade bg-success toast_message_div"
              >
                <Toast.Body className="toast_message">
                  {toastMessage}
                </Toast.Body>
              </Toast>

              <div className="ads_total_div">
                <h3>{title}</h3>
                <h3>{totalAds} Ads</h3>
              </div>

              <Offcanvas
                show={showOffcanvas}
                onHide={handleCloseOffcanvas}
                className="d-block d-lg-none"
                style={{ overflowY: "scroll" }}
                scroll={true}
                backdrop={false}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                  {selectedSubCategory || selectedAccount || selectedCounty ? (
                    <div className="selected_item_div">
                      <div className="selected_item_div">
                        <span className="selected_heading">
                          Your Selection:
                        </span>
                      </div>

                      {selectedSubCategory && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Subcategory:</span>{" "}
                            <span className="selected_div">
                              {selectedSubCategory}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("subcategory")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedAccount && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Account:</span>{" "}
                            <span className="selected_div">
                              {selectedAccount}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("account")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedCounty && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">County:</span>{" "}
                            <span className="selected_div">
                              {selectedCounty}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("county")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedMake && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Make:</span>{" "}
                            <span className="selected_div">{selectedMake}</span>
                          </p>
                          <button
                            onClick={() => handleRemove("make")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedModel && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Model:</span>{" "}
                            <span className="selected_div">
                              {selectedModel}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("model")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedFuel && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Fuel:</span>{" "}
                            <span className="selected_div">{selectedFuel}</span>
                          </p>
                          <button
                            onClick={() => handleRemove("fuel")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedBodyTypes && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Body Type:</span>{" "}
                            <span className="selected_div">
                              {selectedBodyTypes}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("bodytypes")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedEngineSize && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Engine Size:</span>{" "}
                            <span className="selected_div">
                              {selectedEngineSize}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("enginesize")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedColour && (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Colour:</span>{" "}
                            <span className="selected_div">
                              {selectedColour}
                            </span>
                          </p>
                          <button
                            onClick={() => handleRemove("color")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      )}

                      {selectedMinYear ? (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Min Year:</span>{" "}
                            {selectedMinYear}
                          </p>
                          <button
                            onClick={() => handleRemove("minyear")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        selectedMinYear === 0 && (
                          <div className="selected_item_category">
                            <p>
                              <span className="selected_item">Min Year:</span>{" "}
                              {selectedMinYear}
                            </p>
                            <button
                              onClick={() => handleRemove("minyear")}
                              className="cancel_btn"
                            >
                              X
                            </button>
                          </div>
                        )
                      )}

                      {selectedMaxYear ? (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Max Year:</span>{" "}
                            {selectedMaxYear}
                          </p>
                          <button
                            onClick={() => handleRemove("maxyear")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        selectedMaxYear === 0 && (
                          <div className="selected_item_category">
                            <p>
                              <span className="selected_item">Max Year:</span>{" "}
                              {selectedMaxYear}
                            </p>
                            <button
                              onClick={() => handleRemove("maxyear")}
                              className="cancel_btn"
                            >
                              X
                            </button>
                          </div>
                        )
                      )}

                      {selectedMinPrice ? (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Min Price:</span>{" "}
                            {selectedMinPrice}
                          </p>
                          <button
                            onClick={() => handleRemove("minprice")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        selectedMinPrice === 0 && (
                          <div className="selected_item_category">
                            <p>
                              <span className="selected_item">Min Price:</span>{" "}
                              {selectedMinPrice}
                            </p>
                            <button
                              onClick={() => handleRemove("minprice")}
                              className="cancel_btn"
                            >
                              X
                            </button>
                          </div>
                        )
                      )}

                      {selectedMaxPrice ? (
                        <div className="selected_item_category">
                          <p>
                            <span className="selected_item">Max Price:</span>{" "}
                            {selectedMaxPrice}
                          </p>
                          <button
                            onClick={() => handleRemove("maxprice")}
                            className="cancel_btn"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        selectedMaxPrice === 0 && (
                          <div className="selected_item_category">
                            <p>
                              <span className="selected_item">Max Price:</span>{" "}
                              {selectedMaxPrice}
                            </p>
                            <button
                              onClick={() => handleRemove("maxprice")}
                              className="cancel_btn"
                            >
                              X
                            </button>
                          </div>
                        )
                      )}
                    </div>
                  ) : null}

                  <Accordion defaultActiveKey="0">
                  {showCategoryAccordions &&
                    category &&
                    category.length > 0 && (
                      <Accordion.Item eventKey="0">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Categories</h5>
                            </Accordion.Header>
                          </div>

                          <Accordion.Body className="accordion_body">
                            <div className="categories-container">
                              <p
                                className="category"
                                onClick={() => {
                                  handleAllCars("category");
                                }}
                              >
                                Categories (All)
                              </p>
                              {category.map((categories) => (
                                <div key={categories.id} className="category">
                                  <p onClick={() => handleCategory(categories)}>
                                    <IoIosArrowForward onClick={(e) => {
                                          e.stopPropagation(); // Prevent the category click event from firing
                                          handleArrow(categories.slug);
                                        }}/> {categories.category}
                                  </p>
                                  {selectedCategoryId === categories.slug &&
                                    subcategory && (
                                      <ul className="subcategories">
                                        {subcategory.map((subcategories) => (
                                          <li
                                            key={subcategories.id}
                                            onClick={() =>
                                              handleSubCategory(subcategories)
                                            }
                                          >
                                            {subcategories.category} (
                                            {subcategories.listing_count})
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                </div>
                              ))}
                            </div>
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>
                    )}

                  {(subcat == "cars" || subcat == "4x4-wheel-drive") && (
                    <>
                      <Accordion.Item eventKey="3" className="accordion_space">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Makes</h5>
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="accordion_body">
                            <p
                              className="category"
                              onClick={() => {
                                handleAllCars("make");
                              }}
                            >
                              Makes (All)
                            </p>
                            {makes &&
                              makes.length > 0 &&
                              makes.map((make) => (
                                <>
                                  <p
                                    key={make.id}
                                    className="category"
                                    onClick={() => handleMakeClick(make)}
                                  >
                                    {make.make} ({make.listing_count})
                                  </p>
                                </>
                              ))}
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>
                      {makeUrl != "all" && (
                        <div className="models-container">
                          <h5 className="title_model">Models</h5>
                          <p
                            className="category_model"
                            onClick={() => {
                              handleAllCars("model");
                            }}
                          >
                            Models (All)
                          </p>
                          {model.map((model) => (
                            <p
                              key={model.id}
                              className="category_model"
                              onClick={() => handleModelClick(model)}
                            >
                              {model.model} ({model.listing_count})
                            </p>
                          ))}
                        </div>
                      )}
                    </>
                  )}

                  <Accordion.Item eventKey="1" className="accordion_space">
                    <div className="sidebar_categories mt-10">
                      <div className="sidebar_title">
                        <Accordion.Header>
                          <h5 className="category_title">Account</h5>
                        </Accordion.Header>
                      </div>
                      <Accordion.Body className="accordion_body">
                        <p
                          className="category"
                          onClick={() => {
                            handleAllCars("account");
                          }}
                        >
                          Account (All)
                        </p>

                        {account &&
                          account.length > 0 &&
                          account.map((account) => (
                            <div key={account.id} className="category">
                              <p
                                onClick={() =>
                                  handleAccountClick(account.ad_type)
                                }
                                className="account_title"
                              >
                                {account.ad_type} ({account.listing_count})
                              </p>
                            </div>
                          ))}
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2" className="accordion_space">
                    <div className="sidebar_categories mt-10">
                      <div className="sidebar_title">
                        <Accordion.Header>
                          <h5 className="category_title">County</h5>
                        </Accordion.Header>
                      </div>
                      <Accordion.Body className="accordion_body">
                        <p
                          className="category"
                          onClick={() => {
                            handleAllCars("county");
                          }}
                        >
                          Counties (All)
                        </p>
                        {county &&
                          county.length > 0 &&
                          county.map((county) => (
                            <p
                              key={county.id}
                              className="category"
                              onClick={() => handleCountyClick(county)}
                            >
                              {county.county} ({county.listing_count})
                            </p>
                          ))}
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>

                  {(subcat == "cars" || subcat == "4x4-wheel-drive") && (
                    <>
                      <Accordion.Item eventKey="4" className="accordion_space">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Fuels</h5>
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="accordion_body">
                            <p
                              className="account_title"
                              onClick={() => {
                                handleAllCars("fuel");
                              }}
                            >
                              Fuel Type (All)
                            </p>
                            {fuel &&
                              fuel.length > 0 &&
                              fuel.map((fuel) => (
                                <p
                                  key={fuel.id}
                                  className="account_title"
                                  onClick={() => handleFuelClick(fuel.fuel)}
                                >
                                  {fuel.fuel} ({fuel.listing_count})
                                </p>
                              ))}
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>

                      <Accordion.Item eventKey="5" className="accordion_space">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Body Type</h5>
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="accordion_body">
                            <p
                              className="account_title"
                              onClick={() => {
                                handleAllCars("bodyType");
                              }}
                            >
                              Body Type (All)
                            </p>
                            {bodyType &&
                              bodyType.length > 0 &&
                              bodyType.map((bodytype) => (
                                <p
                                  key={bodytype.id}
                                  className="account_title"
                                  onClick={() =>
                                    handleBodyTypesClick(bodytype.body_type)
                                  }
                                >
                                  {bodytype.body_type} ({bodytype.listing_count}
                                  )
                                </p>
                              ))}
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>

                      <Accordion.Item eventKey="6" className="accordion_space">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Colour</h5>
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="accordion_body">
                            <p
                              className="category"
                              onClick={() => {
                                handleAllCars("color");
                              }}
                            >
                              All
                            </p>
                            <div className="category color-grid">
                              {colour &&
                                colour.length > 0 &&
                                colour.map((item) => {
                                  const color = item.colour;
                                  if (color) {
                                    return (
                                      <div
                                        key={item.id || item.colour}
                                        className="color_spilt_div"
                                      >
                                        <div className="color">
                                          <div
                                            className="color_div"
                                            style={{
                                              backgroundColor:
                                                color === "burgundy"
                                                  ? "#800020"
                                                  : color === "bronze"
                                                  ? "#CD7F32"
                                                  : color,
                                            }}
                                            onClick={() =>
                                              handleColorsClick(color)
                                            }
                                          ></div>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>

                      <Accordion.Item eventKey="7" className="accordion_space">
                        <div className="sidebar_categories mt-10">
                          <div className="sidebar_title">
                            <Accordion.Header>
                              <h5 className="category_title">Engine Size</h5>
                            </Accordion.Header>
                          </div>
                          <Accordion.Body className="accordion_body">
                            <p
                              className="category"
                              onClick={() => {
                                handleAllCars("engineSize");
                              }}
                            >
                              Engine Size (All)
                            </p>
                            {engineSize &&
                              engineSize.length > 0 &&
                              engineSize.map((enginesize) => (
                                <p
                                  key={enginesize.id}
                                  className="category"
                                  onClick={() =>
                                    handleEngineSizeClick(
                                      enginesize.engine_size
                                    )
                                  }
                                >
                                  {enginesize.engine_size} (
                                  {enginesize.listing_count})
                                </p>
                              ))}
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>

                      {(subcat=="cars" || subcat=="4x4-wheel-drive")&& (
                          <Accordion.Item
                            eventKey="8"
                            className="accordion_space"
                          >
                            <div className="sidebar_categories mt-10">
                              <div className="sidebar_title">
                                <Accordion.Header>
                                  <h5 className="category_title">Year</h5>
                                </Accordion.Header>
                              </div>
                              <Accordion.Body className="accordion_body">
                                <div>
                                  <label>Filter By: Year</label>

                                  <Slider
                                    key={yearRange.join("-")}
                                    range
                                    min={firstYear[0]}
                                    max={firstYear[1]}
                                    step={1}
                                    defaultValue={yearRange}
                                    onChangeComplete={handleYearRangeChange}
                                    allowCross={false}
                                  />

                                  <div className="slider_label">
                                    <span>
                                      {yearRange[0] === null
                                        ? "0"
                                        : yearRange[0]}
                                    </span>
                                    <span>
                                      {yearRange[1] === null
                                        ? "0"
                                        : yearRange[1]}
                                    </span>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </div>
                          </Accordion.Item>
                        )}

                      {(subcat=="cars" || subcat=="4x4-wheel-drive")&& (
                          <Accordion.Item
                            eventKey="9"
                            className="accordion_space"
                          >
                            <div className="sidebar_categories mt-10">
                              <div className="sidebar_title">
                                <Accordion.Header>
                                  <h5 className="category_title">Price</h5>
                                </Accordion.Header>
                              </div>
                              <Accordion.Body className="accordion_body">
                                <div>
                                  <label>Filter By: Price</label>
                                  <Slider
                                    key={priceRange.join("-")}
                                    range
                                    min={firstPrice[0]}
                                    max={firstPrice[1]}
                                    step={1}
                                    defaultValue={priceRange}
                                    onChangeComplete={handlePriceRangeChange}
                                    tipFormatter={handleTooltip}
                                    allowCross={false}
                                  />
                                  <div className="slider_label">
                                    <span>
                                      {priceRange[0] === null
                                        ? "0"
                                        : priceRange[0]}
                                    </span>
                                    <span>
                                      {priceRange[1] === null
                                        ? "0"
                                        : priceRange[1]}
                                    </span>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </div>
                          </Accordion.Item>
                        )}
                    </>
                  )}
                </Accordion>

                  <button
                    onClick={() => {
                      handleCloseOffcanvas();
                    }}
                    className="login_btn mt-4"
                  >
                    Apply Filter
                  </button>
                </Offcanvas.Body>
              </Offcanvas>

              <div className="product_dropdown_main">
                <div className="product_dropdown">
                  {/* Offcanvas Trigger Button */}
                  <button
                    onClick={handleShowOffcanvas}
                    className="d-block d-lg-none filter_btn"
                  >
                    Filter
                  </button>

                  {saveSearch && (
                    <button
                      onClick={handleSaveSearch}
                      className="save_search_btn"
                    >
                      Save Search Results
                    </button>
                  )}
                </div>

                <div className="product_dropdown product_dropdown_grid">
                  <LuGrid
                    className={`grid_icon ${isGrid ? "active" : ""}`}
                    onClick={toggleGrid}
                  />

                  <LiaThListSolid
                    className={`list_icon ${!isGrid ? "active" : ""}`}
                    onClick={toggleList}
                  />
                </div>
              </div>

              {notFound && (
                  <div class="preview clicks">
                    <div class="image">
                    <img src={megaphone} alt="megaphone"/>
                    </div>
                    <h1>Sorry, no results found.</h1>
                    <p>We have nothing that matches your search. 
                      Browse all of our ads using the <a href={process.env.REACT_APP_API_REPORT} className="main_category">main categories</a>.</p>
                  </div>
              )}

              <div className="load_img_div">
                {(load && !error) && (
                 <FontAwesomeIcon icon={faSpinner} spin className="loader_img" />
                )}
              </div>
              {!error && (
                <div>
                  <div className={`${grid ? "grid" : "list"}`}>
                    {grid &&
                      product.map((product) => {
                        return (
                          <>
                            <CategoryProductCard
                              key={product.id}
                              product={product}
                              wishlist={wishlist}
                              isInWishlist={isInWishlist(product.id)}
                              updateWishlist={updateWishlist}
                            />
                          </>
                        );
                      })}
                    {isGrid &&
                      product.map((product) => {
                        return (
                          <>
                            <CategoryListing
                              key={product.id}
                              product={product}
                              wishlist={wishlist}
                              isInWishlist={isInWishlist(product.id)}
                              updateWishlist={updateWishlist}
                            />
                          </>
                        );
                      })}
                  </div>

                  {load == false && product && totalPages && totalPages > 1 && (
                    <div className="pagination">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default CarSearch;
