import React, { useEffect, useState } from "react";
import "./OptionNavbar.css";
import { Button } from "react-bootstrap";
import { RiPencilFill } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import CategoryComponent from "./CategoryComponent";
import { DealerApi } from "./Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const OptionNavbar = ({balance}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);

  const [boxesData, setBoxesData] = useState([]);
  const [load, setLoad] = useState(true);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const boxes = () => {
    setLoad(true);
    fetch(`${DealerApi}/category`)
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        setBoxesData(data.body.result);
      })
      .catch((error) => {
        setLoad(false);
      });
  }
 
  useEffect(() => {
    setShow(false);
  }, [location.pathname]);

  const handleplaceadd = (event) => {
    event.preventDefault();
    Cookies.remove("listingToken");
    if (tokenObject && tokenObject.user_type == "admin") {
      window.open(
        `${process.env.REACT_APP_ADMIN_APP_API_URL}/advert/index/private`,
        "_self"
      );
    } else if (tokenObject) {
      if (balance && balance.result === true) {
        navigate("/online_ad");
      } else {
        navigate("/payments");
      }
    } else {
      navigate("/login");
    }
  };

  const handleMouseDown = (event) => {
    if (event.button === 1) {
      event.preventDefault();
      Cookies.remove("listingToken");
      if (tokenObject && tokenObject.user_type == "admin") {
        window.open(
          `${process.env.REACT_APP_ADMIN_APP_API_URL}/advert/index/private`,
          "_blank"
        );
      } else if (tokenObject) {
        if (balance && balance.result === true) {
          window.open("/online_ad");
        } else {
          window.open("/payments");
        }
      } else {
        window.open("/login");
      }
    }
  };

  // const handleBusiness = () => {
  //   window.location.href = "/business_directory/search/s/all/location/all";
  // };

  // const handlenewest = () => {
  //   navigate(`/search/all`);
  // };

  const handleBrowseAds = () => {
    boxes();
    setShow(!show);
  };

  const handleStatus = (status) => {
    setShowModal(status);
  };

  useEffect(() => {
    // Check if screen width is less than or equal to 500px
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    handleResize(); // Check on initial render

    window.addEventListener("resize", handleResize); // Listen for resize events

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on component unmount
    };
  }, []);

  useEffect(() => {
    if (isMobile && showModal) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [isMobile, showModal]);

  return (
    <>
      {isMobile && showModal && <div className="overlay" id="overlay"></div>}
      <div className="optionnavbar_main">
        <div className="optionnavabar_btn_div">
          {location.pathname === "/" ? (
            <Button
              as={Link}
              to="/search/all"
              variant="transparent"
              className="optionnavbar_btn_browse"
              // onClick={handlenewest}
            >
              <FaSearch /> Newest Listing
            </Button>
          ) : (
            <Button
              variant="transparent"
              className="optionnavbar_btn_browse"
              onClick={handleBrowseAds}
            >
              <FaSearch /> Browse Ads
            </Button>
          )}
          <Button
            as={Link}
            variant="transparent"
            className="optionnavbar_btn"
            onClick={handleplaceadd}
            onMouseDown={handleMouseDown}
          >
            <RiPencilFill /> Place An Ad
          </Button>
          <Button
            as={Link}
            to="/business_directory/search/s/all/location/all"
            variant="transparent"
            className="optionnavbar_btn_browse"
            // onClick={handleBusiness}
          >
            <FaLocationDot /> Business Directory
          </Button>
        </div>
      </div>
      {show && (
        <>
          {load ? (
            <div className="place_ad_div_section_loader">
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                className="loader_category_img"
              />
            </div>
          ) : (
            <div className="grid-container place_ad_div_section container">
              <CategoryComponent status={handleStatus} boxesData={boxesData} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OptionNavbar;
