import React, { useState } from "react";
import "./ProductCard.css";
import { FaHeart, FaRegHeart, FaUser } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import axios from "axios";
import { DealerApi } from "./Api";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";

const ProductCard = ({ product, isInWishlist, wishlist, updateWishlist }) => {
  const navigate = useNavigate();
  const [isWishlist, setIsWishlist] = useState(isInWishlist);

  const handleAddToWishlist = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const token = Cookies.get("loginToken");
    const tokenObject = token && JSON.parse(token);

    if (tokenObject) {
      axios
        .post(`${DealerApi}/wishlist`, {
          user_id: tokenObject.id,
          listing_id: product.id,
        })
        .then(() => {
          setIsWishlist(true);
          if (wishlist == undefined) {
            updateWishlist([product]);
          } else {
            updateWishlist([...wishlist, product]);
          }
        })
        .catch((error) => {});
    } else {
      navigate("/login");
    }
  };

  const handleRemoveFromWishlist = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const token = Cookies.get("loginToken");
    const tokenObject = token && JSON.parse(token);
    axios
      .post(`${DealerApi}/wishlist/delete`, {
        user_id: tokenObject.id,
        listing_id: product.id,
      })
      .then(() => {
        setIsWishlist(false);
        updateWishlist(wishlist.filter((itemId) => itemId !== product.id));
      })
      .catch((error) => {});
  };

  const convertToUrl = (text) => {
    text = text.trim().toLowerCase();
    text = text.replace(/\s+/g, "-");
    text = text.replace(/(\d+)\.(\d+)/g, "$1-$2");
    text = text.replace(/[^a-z0-9-]/g, "");
    return text;
  };

  // const handleProduct = (product) => {
  //   const urlText = convertToUrl(product.title);
  //   const targetUrl = `//${product.id}/${urlText}`;
  //   window.location.href = targetUrl;
  // };

  return (
    <>
      <a href={`/${product.category_slug}/${product.sub_category_slug}/${product.user_location}/${product.ad_type}/${product.id}/${convertToUrl(product.title)}`} className="product_link">
        <div className="card_main">
          <div className="product_img">
            <img
              src={product.picture.url.large}
              alt="Product"
              className="card_img"
            />
          </div>
          <div className="card_detail">
            <div className="user_div">
              <div>
                <p className="user_detail">
                  <span>
                    <FaUser />
                  </span>
                  {product.ad_type}
                </p>
              </div>
              <p>
                {isWishlist ? (
                  <FaHeart
                    className=" wishlist wishlist_fill"
                    onClick={handleRemoveFromWishlist}
                  />
                ) : (
                  <FaRegHeart
                    className="wishlist"
                    onClick={handleAddToWishlist}
                  />
                )}
              </p>
            </div>
            <div className="product_detail">
              <div className="product_line_divide">
                <hr />
              </div>
              <div className="product_line">
                <hr />
              </div>
            </div>
            <div className="product_name_div">
              <span className="product_name">{product.title}</span>
            </div>
            <div className="location_div">
              <span className="location_detail">
                <span>
                  <MdLocationOn />
                </span>
                {product.location}
              </span>
            </div>
            <div class="d-flex justify-content-between">
              <span class="product_price">
                {product.currency_sign} {product.price}
              </span>
              <span class="date">
                {" "}
                {product.days === 0 || product.days === 1
                  ? `${product.days} day`
                  : `${product.days} days`}
              </span>
            </div>
          </div>
        </div>
      </a>
    </>
  );
};

export default ProductCard;
