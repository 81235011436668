import React, { useState } from "react";
import "./CategoryProductCard.css";
import { FaHeart, FaRegHeart, FaUser } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import Cookies from "js-cookie";
import axios from "axios";
import { DealerApi } from "./Api";
import { useNavigate } from "react-router";

const CategoryProductCard = ({
  product,
  isInWishlist,
  wishlist,
  updateWishlist,
}) => {
  
  const navigate = useNavigate();
  const [isWishlist, setIsWishlist] = useState(isInWishlist);

  const handleAddToWishlist = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const token = Cookies.get("loginToken");
    const tokenObject = token && JSON.parse(token);

    if (!tokenObject) {
      navigate("/login");
      return;
    }

    axios
      .post(`${DealerApi}/wishlist`, {
        user_id: tokenObject.id,
        listing_id: product.id,
      })
      .then((res) => {
        setIsWishlist(true);

        if (wishlist == undefined) {
          updateWishlist([product]);
        } else {
          updateWishlist([...wishlist, product]);
        }
      })
      .catch((error) => {
      });
  };

  const handleRemoveFromWishlist = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const token = Cookies.get("loginToken");
    const tokenObject = token && JSON.parse(token);

    axios
      .post(`${DealerApi}/wishlist/delete`, {
        user_id: tokenObject.id,
        listing_id: product.id,
      })
      .then(() => {
        setIsWishlist(false);
        updateWishlist(wishlist.filter((itemId) => itemId !== product.id));
      })
      .catch((error) => {
      });
  };

  const convertToUrl = (text) => {
    text = text.trim().toLowerCase();
    text = text.replace(/\s+/g, "-");
    text = text.replace(/(\d+)\.(\d+)/g, "$1-$2");
    text = text.replace(/[^a-z0-9-]/g, "");
    return text;
  };

  // const handleProduct = () => {
  //   const urlText = convertToUrl(product.title);
  //   // navigate(`/product/${product.id}/${urlText}`);
  //   const targetUrl = `/product/${product.id}/${urlText}`;
  //   window.location.href= targetUrl
  // };

  return (
    <>
    <a href={`/${product.category_slug}/${product.sub_category_slug}/${product.user_location}/${product.ad_type}/${product.id}/${convertToUrl(product.title)}`} className="product_link">
      <div className="category_card_main">
        <div className="product_img">
          <img
            src={product.picture.url.large}
            alt="Product"
            className="category_card_img"
          />
        </div>
        <div className="category_card_detail">
          <div className="category_user_div">
            <div>
              <p className="category_user_detail">
                <span>
                  <FaUser />
                </span>
                {product.ad_type}
              </p>
            </div>
            <p>
              {isWishlist ? (
                <FaHeart
                  className="wishlist wishlist_fill"
                  onClick={handleRemoveFromWishlist}
                />
              ) : (
                <FaRegHeart
                  className="wishlist"
                  onClick={handleAddToWishlist}
                />
              )}
            </p>
          </div>
          <div className="category_product_detail">
            <div className="category_product_line_divide">
              <hr />
            </div>
            <div className="category_product_line">
              <hr />
            </div>
          </div>
          <div className="category_product_name_div">
            <span className="category_product_name">{product.title}</span>
          </div>
          <div className="category_location_div">
            <span className="category_location_detail">
              <span>
                <MdLocationOn />
              </span>
              {product.location}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="category_price">
              {product.currency_sign} {product.price}
            </span>
            <span className="category_date"> {product.days === 0 || product.days === 1
              ? `${product.days} day`
              : `${product.days} days`}</span>
          </div>
        </div>
      </div>
    </a>
    </>
  );
};

export default CategoryProductCard;