import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../Page/MyAd.css"

const ConfirmationDialog = ({ show, handleClose, handleConfirm }) => {

    const handleBackdropClick = (event) => {
      event.stopPropagation();
    };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      onClick={handleBackdropClick}
      backdrop="static"
      backdropClassName="transparent-backdrop"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Confirm Deletion</Modal.Title>
        <Button
          variant="light"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this ad?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog;