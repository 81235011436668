import React, { useState } from "react";
import "./ReportPage.css";
import { useParams } from "react-router";
import axios from "axios";
import { DealerApi } from "./Api";
import { Nav, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import Heder from "./Heder";

const ReportPage = ({setting}) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
    
  const url = useParams();

  const baseURL = process.env.REACT_APP_API_REPORT;
  let iframeSrc = `${baseURL}/search/all`;

  if (
    url.category &&
    url.subcategory &&
    url.county &&
    url.adtype &&
    url.id &&
    url.slug
  ) {
    iframeSrc = `${baseURL}/${url.category}/${url.subcategory}/${url.county}/${url.adtype}/${url.id}/${url.slug}`;
  }

  const initialValues = {
    name: "",
    email: "",
    telephone: "",
    message: "",
    url:
      url.category &&
      url.subcategory &&
      url.county &&
      url.adtype &&
      url.id &&
      url.slug
        ? `${baseURL}/${url.category}/${url.subcategory}/${url.county}/${url.adtype}/${url.id}/${url.slug}`
        : `${baseURL}/search/all`,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const validateForm = () => {
    let errors = {};

    if (!formValues.name) {
      errors.name = "Name is required";
    }

    if (!formValues.email) {
      errors.email = "Email is required";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      axios
        .post(`${DealerApi}/report`, formValues, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setSuccess(true);
          setTimeout(() => {
            setFormValues(initialValues);
            setSuccess(false);
          }, 5000);
        })
        .catch((err) => {
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 5000);
        });
    } else {
      setFormErrors(errors);
    }
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    if (event.button === 1) {
      window.open(
        `${process.env.REACT_APP_API_REPORT}/${url.category}/${url.subcategory}/${url.county}/${url.adtype}/${url.id}/${url.slug}`,
        "_blank"
      );
    }
  };

  const handleFullView = (event) => {
    event.preventDefault();
    if (
      url.category &&
      url.subcategory &&
      url.county &&
      url.adtype &&
      url.id &&
      url.slug
    ) {
      window.location.href = `${process.env.REACT_APP_API_REPORT}/${url.category}/${url.subcategory}/${url.county}/${url.adtype}/${url.id}/${url.slug}`;
    } else {
      window.location.href = `${process.env.REACT_APP_API_REPORT}/search/all`;
    }
  };

  return (
    <>
      <Heder name="report an advert" setting={setting}/>
      <div className="advert_main_div">
        <p className="advert_heading">Report an Advert</p>
      </div>
      <div className="container">
        <Toast
          className="fade bg-success contact_toast_message_div"
          show={success}
          onClose={() => setSuccess(false)}
        >
          <Toast.Body className="toast_message">
            Report successfully!
          </Toast.Body>
        </Toast>

        <Toast
          show={error}
          onClose={() => setError(false)}
          className="fade bg-danger contact_toast_message_div"
        >
          <Toast.Body className="toast_message">Error in Report.</Toast.Body>
        </Toast>
        <div className="report_main">
          <div className="advert_info">
            <div className="report_form">
              <form onSubmit={handleSubmit}>
                <div className="place_ad_div">
                  <div className="place_ad_left_portion">
                    <label className="register_label company_name">
                      Your Name <span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      className="register_input"
                      name="name"
                      value={formValues.name}
                      onChange={handleChange}
                    />

                    <div className="error-message">{formErrors.name}</div>

                    <label className="register_label company_name">
                      Your Email <span className="astrick">*</span>
                    </label>
                    <input
                      type="email"
                      className="register_input"
                      name="email"
                      value={formValues.email}
                      onChange={handleChange}
                    />

                    <div className="error-message">{formErrors.email}</div>

                    <label className="register_label company_name">
                      Your Telephone
                    </label>
                    <input
                      type="text"
                      className="register_input"
                      name="telephone"
                      value={formValues.telephone}
                      onChange={handleChange}
                    />

                    <label className="register_label company_name">
                      Comments
                    </label>
                    <textarea
                      className="register_input_description"
                      rows="8"
                      name="message"
                      value={formValues.message}
                      onChange={handleChange}
                    />

                    <div className="submit_place_ad">
                      <button type="submit" className="create_account_btn">
                        Report this Ad
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="report_suggestion">
              <div className="advert_div">
                <iframe
                  src={iframeSrc}
                  // src={`${process.env.REACT_APP_API_REPORT}/${url.category}/${url.subcategory}/${url.county}/${url.adtype}/${url.id}/${url.slug}`}
                  title="Website Report"
                  width="100%"
                  height="600px"
                  style={{ margin: "auto" }}
                />
                <p className="fullView">
                  <Nav.Link
                    as={Link}
                    onClick={handleFullView}
                    onMouseDown={handleMouseDown}
                  >
                    <span className="full_view_span">VIEW FULL AD</span>
                  </Nav.Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportPage;
