// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { DealerApi } from "./Api";

const MapEmbed = ({ apiKey, countryName, latitude, longitude }) => {

  // const [clientId, setClientId] = useState([]);


  // useEffect(() => {
  //   axios.get(`${DealerApi}/keys`)
  //   .then((response) => {
  //     setClientId(response.data.body.result.google_api_key)
  //   })
  //   .catch((err)=> {
  //   })
  //   },[])

  const generateMapUrl = (latitude, longitude, countryName) => {
    return `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${latitude},${longitude}&zoom=8`;
  };

  const mapUrl = generateMapUrl(latitude, longitude, countryName);

  return (
    <iframe
      title={`Map of ${countryName}`}
      src={mapUrl}
      width="100%"
      height="200"
      allowFullScreen=""
      aria-hidden="false"
      tabIndex="0"
    />
  );
};

export default MapEmbed;