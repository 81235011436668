import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./Faq.css";
import Heder from "../Component/Heder";

const Faq = ({setting}) => {


  return (
    <>
    <Heder name="FAQ" setting={setting}/>
      <div className="faq_main_div">
        <p className="faq_heading">Frequently Asked Questions</p>
      </div>

      <div className="container">
        <div className="faq_div">
          <Accordion defaultActiveKey={["0"]} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>How do I report a scam?
              </Accordion.Header>
              <Accordion.Body>
                We are always eager to hear of unscrupulous traders/advertisers.
                If you have any information you feel would be important, please
                contact us on 074 9126410. Please be assured that your
                confidentiality will be guaranteed by law under the Data
                Protection Act 2018.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                How do I report an advert which may be illegal or abusive?{" "}
              </Accordion.Header>
              <Accordion.Body>
                All adverts online have a 'Report Ad' link which allows you to
                highlight adverts which may be abusive or illegal. The link can
                be found on the right hand side, above the advert copy. The
                information you provide will help our customer services team
                decide the legality of the advert.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Can you remove an advert for an illegal item?
              </Accordion.Header>
              <Accordion.Body>
                As and when we can establish the illegality of an advert
                appearing either online or within our publications, we will
                remove the advert and put steps in place to ensure that it
                doesn't appear again.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                What can I do if I think these goods may be stolen?
              </Accordion.Header>
              <Accordion.Body>
                If you have reason to believe that items purchased through The
                Dealer "Free-Ads" Paper publication or thedealer.ie website were
                stolen, please contact your local Garda Station. You will find
                contact details for your area online and the Gardai will be able
                to advise you on the correct course of action.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                What can I do if an item stolen from me has appeared on your
                site or paper?
              </Accordion.Header>
              <Accordion.Body>
                If you have reason to believe that items stolen from you have
                appeared in The Dealer "Free-Ads" Paper publication or
                thedealer.ie website, please contact your local Garda Station
                and the Gardai will be able to advise you on the correct course
                of action. Please also phone The Dealer "Free-Ads” Paper Ltd. on
                074 9126410 or email info@thedealer.ie. Please be assured that
                your confidentiality will be guaranteed by law under the Data
                Protection Act 2018.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                How can I be careful when meeting buyers at home?
              </Accordion.Header>
              <Accordion.Body>
                For personal ease and safety, always ensure that there is a
                friend with you before you allow someone into your home. Meet
                during daytime hours and keep the meeting formal.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Are there any extra safety precautions I can take when selling
                valuable items?
              </Accordion.Header>
              <Accordion.Body>
                It is advisable to negotiate the price before meeting and agree
                on the method of payment preferably cash or bank draft. Beware
                of offers to pay by cheque. If you are not willing to negotiate
                on price let the buyer know before you meet. Choose a safe
                location to meet and bring a friend with you.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                What is the best way to accept money?
              </Accordion.Header>
              <Accordion.Body>
                For items that are worth only a few pounds, it is easier and
                more convenient to pay in cash. To be safe, when you go to visit
                the person selling the item you are interested in, don’t take
                more money than you are planning on spending. Beware of offers
                to pay by cheque. Cash or bank drafts are safer.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                Should I send the goods before receiving the money?
              </Accordion.Header>
              <Accordion.Body>
                Unfortunately, we are unable to interfere in the private
                transactions between a buyer and a seller. The most
                straightforward way is directly in person and payment be made in
                cash or bank draft. If you send goods before receiving payment,
                you are at risk of not getting paid.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                What can I do when I've sent the advertiser my money but he/she
                has not sent the goods?
              </Accordion.Header>
              <Accordion.Body>
                Unfortunately, we are unable to interfere in the private
                transactions between a buyer and a seller. However, if you can
                provide us with a letter of complaint, we will keep this on file
                under the advertiser's records. You may wish to contact your
                local Citizens Information bureau who will be able to advise you
                on the best course of action to take. The number for your local
                office can be found online.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="10">
              <Accordion.Header>
                What should I do when the advertiser wants me to send cash up
                front?
              </Accordion.Header>
              <Accordion.Body>
                Don't do it. We recommend that buyers NEVER send cash or cheques
                through the post, or place money directly into a seller's bank
                account, in advance of receiving goods.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="11">
              <Accordion.Header>
                What if the item turns out to be faulty?
              </Accordion.Header>
              <Accordion.Body>
                Once you have bought an item from a private seller, you have no
                legal comeback if it turns out to be faulty. Therefore, check
                the goods thoroughly before handing over the money. If the
                seller still has the receipts, or a warranty, so much the
                better. It is advisable to switch on electrical items to make
                sure they are working. Check the sizing and condition for
                clothing items, for example and try on if possible.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="12">
              <Accordion.Header>
                How can I be careful when meeting sellers at their home?
              </Accordion.Header>
              <Accordion.Body>
                Most private advertisers sell their goods from home. For
                personal ease and safety, always ensure you take someone with
                you. If you're answering a home service ad, ask the advertiser
                to produce identity and proof of qualification before inviting
                them into your home
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="13">
              <Accordion.Header>
                What if travel is involved in a transaction?
              </Accordion.Header>
              <Accordion.Body>
                If travel is involved in the transaction, always remember to
                write down the contact details of the people you are visiting,
                or the people who are visiting you. If, for whatever reason, you
                are unable to make the appointment, notify them in advance
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Accordion className="accordion_main">
            <Accordion.Item eventKey="14">
              <Accordion.Header>What is a Vendor?</Accordion.Header>
              <Accordion.Body>
                A vendor is a person or business, who is allowed the facility to
                advertise and sell goods and services directly through
                thedealer.ie allowing payment to be made directly to the
                advertisers relevant bank account.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
        </div>
      </div>
    </>
  );
};

export default Faq;