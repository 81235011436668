import React, { useState } from "react";
import "./ForgetPswd.css";
import { DealerApi } from "./Api";
import axios from "axios";
import { useNavigate } from "react-router";
import { Alert } from "react-bootstrap";
import Heder from "./Heder";

const ForgetPswd = ({setting}) => {

  const initialValues = {
    email: "",
  };

  const [forgetpswdFormValues, setForgetPswdFormValues] = useState(initialValues);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForgetPswdFormValues({ ...forgetpswdFormValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const validateForm = () => {
    let errors = {};

    if (!forgetpswdFormValues.email) {
      errors.email = "Email is required";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      try {
        const response = await axios.post(
          `${DealerApi}/auth/password/reset/email`,
          forgetpswdFormValues,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setError(null);
        setSuccess(response.data.body.message);
        setTimeout(() => {
          navigate("/");
          setSuccess(null);
        }, 1000);
      } catch (err) {
        setError(err.response.data.body.message);
        setSuccess(null);
        setTimeout(() => {
          setError(null);
        }, 1000)
      }
    }
    else {
      setFormErrors(errors);
    }
  };

  return (
    <>
    <Heder name="forgot password" setting={setting}/>
    
      <div className="advert_main_div">
        <p className="advert_heading">Forgot Password</p>
      </div>

      <div className="container">
        <div className="forgot_pswd_main">
          <div className="forgot_pswd_form">
            <h1 className="forgotpswd_heading">Reset Your Password</h1>
            {success && (
              <Alert variant="success" style={{ fontSize: "20px" }}>
                {success}
              </Alert>
            )}
            {error && (
              <Alert variant="danger" style={{ fontSize: "20px" }}>
                {error}
              </Alert>
            )}
            <div className="forgotpswd_div">
              <form onSubmit={handleSubmit}>
                <label className="forgotpswd_label">
                  Enter your email address below and we'll send you a new
                  password.
                </label>
                <input
                  type="text"
                  className="forgotpswd_input"
                  name="email"
                  value={forgetpswdFormValues.email}
                  onChange={handleChange}
                />
                <div className="error-message">
                  {formErrors.email}
                </div>
                <div className="reset_btn_div">
                  <input
                    type="submit"
                    name="submit"
                    value="Reset Password"
                    id="submit"
                    class="reset_button"
                  ></input>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPswd;